import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Branch from "../componant/branch/branch";
import Review from "../componant/review/review";
import { colors } from "../../../constants/colors";
import { imagesRefinace, imgs } from "../../../constants/images";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import Header2 from "../../../components/header/header2";
import Footer2 from "../../../components/footer/footer2";
import * as Style from "../style";
import LarkService from "../../../utils/lark/lark";

interface WindowWithAdTech extends Window {
  adTechTrackerInit?: (key: string) => void;
  tmfire?: (publicKey: string, eventData: object) => void;
}

export default function Refinance() {
  const history = useHistory();
  const theme = useTheme();
  // breakpoint "md" (>=900px) ตามค่า default ของ MUI
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [seconds, setSeconds] = useState(0);


  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_API_ADTECH || '';
    script.async = true;
    const customWindow = window as WindowWithAdTech;
    script.onload = () => {
      if (customWindow.adTechTrackerInit) {
        customWindow.adTechTrackerInit(process.env.REACT_APP_API_ADTECH_KEY || '');
      } else {
        new LarkService('adTechTrackerInit sp_turn1 function is not available', 'red').send(
          `error :${customWindow}`
        );
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const customWindow = window as WindowWithAdTech;
  const publicKey = process.env.REACT_APP_API_ADTECH_KEY || '';

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => {
        const newSeconds = prevSeconds + 1;
        if ([3, 15, 30, 60].includes(newSeconds)) {
          const eventData = {
            data: {
              event_name: `View ${newSeconds} sec`,
              custom_data: {
                currency: "thb",
                value: 10
              }
            }
          };
          const messageLark = `data:  event_name: View ${newSeconds} sec`;
          if (customWindow.tmfire) {
            customWindow.tmfire(publicKey, eventData);
            // new LarkService('adTechTracker sp_leasing1 send event', 'green').send(
            //   `send : ${messageLark}`
            // );
          } else {
            new LarkService('adTechTrackerInit sp_leasing1 send event error', 'red').send(
              `error : ${messageLark}`
            );
          }
        }
        return newSeconds;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const triggeredRef = useRef(new Set());

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const totalScroll = docHeight - windowHeight;
      if (totalScroll <= 0) return;

      const scrollPercent = (scrollTop / totalScroll) * 100;
      if (scrollPercent >= 25 && !triggeredRef.current.has(25)) {
        sendEvent(25)
        console.log("Scroll 25%");
        triggeredRef.current.add(25);
      }
      if (scrollPercent >= 50 && !triggeredRef.current.has(50)) {
        sendEvent(50)
        console.log("Scroll 50%");
        triggeredRef.current.add(50);
      }
      if (scrollPercent >= 75 && !triggeredRef.current.has(75)) {
        sendEvent(75)
        console.log("Scroll 75%");
        triggeredRef.current.add(75);
      }
      if (scrollPercent >= 95 && !triggeredRef.current.has(95)) {
        sendEvent(95)
        console.log("Scroll 95%");
        triggeredRef.current.add(95);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sendEvent = (value: number) => {
    const eventData = {
      data: {
        event_name: `Scroll ${value} %`,
        custom_data: {
          currency: "thb",
          value: 10
        }
      }
    };
    const messageLark = `data:  event_name: Scroll ${value}`;
    if (customWindow.tmfire) {
      customWindow.tmfire(publicKey, eventData);
      // new LarkService('adTechTracker sp_leasing1 send event', 'green').send(
      //   `send : ${messageLark}`
      // );
    } else {
      new LarkService('adTechTrackerInit sp_leasing1 send event error', 'red').send(
        `error : ${messageLark}`
      );
    }
  }

  const qualifications = [
    "นักศึกษา อายุ 18 ปีขึ้นไป",
    "บุคคลธรรมดาอายุ 20-60 ปี",
    "สัญชาติไทย",
    "ไม่จำกัดอาชีพ",
  ];

  const documents = [
    "บัตรประชาชน",
    "สลิปเงินเดือน หรือเอกสารแสดงรายได้",
    "ระเบียนนักศึกษา หรือบัตรนักศึกษา\nตัวจริง (สำหรับนักศึกษา)",
  ];

  const navigateToRefinance = () => {
    const eventData = {
      data: {
        event_name: "Register_คลิก",
        custom_data: {
          currency: "thb",
          value: 10
        }
      }
    };
    const messageLark = `data:  event_name: ${eventData.data.event_name}`;
    if (customWindow.tmfire) {
      customWindow.tmfire(publicKey, eventData);
      // new LarkService('adTechTrackerInit sp_turn1 send event', 'green').send(
      //   `send : ${messageLark}`
      // );
    } else {
      new LarkService('adTechTrackerInit sp_turn1 send event error', 'red').send(
        `error : ${messageLark}`
      );
    }
    history.push(`/register/?type=REFINANCE`);
  }

  const navigateToLine = () => {
    window.location.href = "https://lin.ee/gRM3iu9";
  }

  const sectionRef = useRef<HTMLDivElement>(null);
  const sectionRef2 = useRef<HTMLDivElement>(null);
  const sectionRef3 = useRef<HTMLDivElement>(null);
  const sectionRef4 = useRef<HTMLDivElement>(null);


  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };

  const scrollToSection2 = () => {
    if (sectionRef2.current) {
      sectionRef2.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };
  const scrollToSection3 = () => {
    if (sectionRef3.current) {
      sectionRef3.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };
  const scrollToSection4 = () => {
    if (sectionRef4.current) {
      sectionRef4.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };

  return (
    <>
      <Header2 scrollToSection={scrollToSection} scrollToSection2={scrollToSection2} scrollToSection3={scrollToSection3} scrollToSection4={scrollToSection4} />
      <div className="pt-5" ref={sectionRef}>
        {/* Banner 1 & 2 */}
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            src={isMdUp ? imagesRefinace.UFWEB2_1 : imagesRefinace.MUF2_1}
            width="100%"
            alt="Banner 1"
          />
          <Box sx={{
            position: "absolute",
            bottom: { lg: "35%", md: '31%', xs: '47%' },
            left: { lg: "71%", md: '71%', xs: '51%' },
            transform: "translateX(-50%)",
          }}>
            <motion.div
              animate={{ scale: [1, 1.1, 1] }} // Pulsing effect
              transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFCC00",
                  color: "#174ea6",
                  fontWeight: "bold",
                  whiteSpace: 'nowrap',
                  px: 3,
                  py: 1,
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#FFD700",
                  },
                }}
                onClick={() => (navigateToRefinance())}
              >
                <Typography
                  style={{ fontWeight: 700 }}
                  sx={{ typography: { xl: "h4", sm: 'body1', xs: "h6" } }}>
                  สนใจไอโฟนแลกเงิน คลิก
                </Typography>
              </Button>
            </motion.div>
          </Box>
        </Box>

        <Box
          component="img"
          src={isMdUp ? imagesRefinace.UFWEB2_2 : imagesRefinace.MUF2_2}
          width="100%"
          alt="Banner 2"
        />

        {/* Section 1 */}
        <Style.Container>
          <Box>
            <Grid container>
              {/* Left Image */}
              <Grid xs={12} md={12} lg={7} display="flex" justifyContent={{ md: "center", lg: "flex-start" }} alignItems="center">
                <Box
                  component="img"
                  src={isMdUp ? imagesRefinace.UFWEB2_3 : imagesRefinace.MUF2_3}
                  alt="Service"
                  sx={{
                    width: { xs: "100%", md: "90%", lg: "100%", xl: '100%' },
                  }}
                />
              </Grid>

              {/* Right Text */}
              <Grid xs={12} md={12} lg={5}>
                <Box>
                  <Typography
                    style={{ fontWeight: 200, lineHeight: 1.8 }}
                    sx={{
                      typography: { xl: "h5", lg: "body1", md: "body2", xs: "body1" },
                      lineHeight: { lg: 2.0, md: 1.8, xs: 1.6, },
                      pt: { xs: 2, md: 0, lg: 7 },
                      // whiteSpace: 'nowrap'
                    }}
                  >
                    คุณก็สามารถใช้บริการไอโฟนแลกเงินได้ง่าย ๆ ไม่ต้อง
                    <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />
                    เสียเวลาไปกับขั้นตอนที่ยุ่งยาก หรือกังวลเรื่องเอกสาร
                    <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />
                    มากมายเพียงแค่ลงทะเบียนแล้วรอผล
                  </Typography>
                  <Typography
                    fontWeight={500}
                    color={colors.themeMainColor}
                    sx={{
                      pt: { xs: 0, md: 1, lg: 3 },
                      typography: { xl: "h4", lg: "h5", md: "h6", xs: "h6" }
                    }}
                  >
                    อนุมัติภายใน 10 นาที
                  </Typography>
                  <Typography
                    style={{ fontWeight: 200, lineHeight: 1.8 }}
                    sx={{
                      typography: { xl: "h5", lg: "body1", md: "body2", xs: "body1" },
                      lineHeight: { lg: 1.8, md: 1.8, xs: 1.6, },
                      pt: { xs: 0, md: 1, lg: 2 },
                      pb: { xs: 0, md: 2, lg: 0 },
                      // whiteSpace: 'nowrap'
                    }}
                  >
                    เมื่ออนุมัติผ่านแล้ว สามารถนัดหมายเข้ามาที่สาขาเพื่อ
                    <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />

                    ให้ทางทีมงานประเมินเครื่องและเสนอราคา หากลูกค้า
                    <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />

                    พึงพอใจสามารถรับเงินสดได้ทันที
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Style.Container>

        {/* Section 2: เหตุผลที่ลูกค้าไว้วางใจ */}
        <Style.Container>
          <Grid container >

            <Grid xs={12} md={12} lg={6} xl={6}
              pl={{ lg: 8, xs: 0 }}
            >
              <>
                <Typography
                  style={{ fontWeight: 500, }}
                  sx={{
                    typography: { xl: "h4", lg: "h5", md: "h6", xs: "h6" },
                    // typography: { xl: "h5", lg: "body1", md: "body2", xs: "body1" },
                  }}>
                  เหตุผลที่ลูกค้ามากมายไว้วางใจ Ufriend
                </Typography>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 200, lineHeight: 1.6 }}
                  sx={{
                    pt: { xl: 2, md: 1, xs: 1 },
                    typography: { xl: "h5", lg: "body1", md: "body2", xs: "body1" },
                    lineHeight: { lg: 1.8, md: 1.8, xs: 1.6, },
                  }}
                >
                  <Box sx={{ display: { xs: "none", lg: "contents" } }}>
                    &nbsp;&nbsp;&nbsp;
                  </Box>เราไม่ได้โดดเด่นแค่เรื่องการบริการที่รวดเร็วทันใจเท่านั้น
                  <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />

                  แต่เรายังมีสาขาที่เปิดให้บริการทั่วประเทศ พร้อมระบบการ
                  <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />

                  ผ่อนชำระที่สะดวกและง่ายผ่านแอป
                </Typography>
                <Typography
                  style={{ fontWeight: 200, lineHeight: 1.6 }}
                  sx={{
                    pt: { xl: 4, md: 1, xs: 3 },
                    typography: { xl: "h5", lg: "body1", md: "body2", xs: "body1" },
                    lineHeight: { lg: 1.8, md: 1.8, xs: 1.6, },
                  }}
                >
                  <Box sx={{ display: { xs: "none", lg: "contents" } }}>
                    &nbsp;&nbsp;&nbsp;
                  </Box>และที่สำคัญบริการไอโฟนแลกเงินของเรา ยังช่วยให้คุณ
                  <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />

                  ได้เครื่องกลับไปใช้ หมดปัญหาเรื่องขาดการติดต่อ
                </Typography>
                <Typography
                  style={{ fontWeight: 200, lineHeight: 1.6 }}
                  sx={{
                    pt: { xl: 4, md: 1, xs: 3 },
                    typography: { xl: "h5", lg: "body1", md: "body2", xs: "body1" },
                    lineHeight: { lg: 1.8, md: 1.8, xs: 1.6, },
                    pb: { xs: 2, md: 2, lg: 0 },
                  }}
                >
                  <Box sx={{ display: { xs: "none", lg: "contents" } }}>
                    &nbsp;&nbsp;&nbsp;
                  </Box>หรือพลาดโอกาสสำคัญในชีวิต เพราะเราเชื่อว่าการช่วยเหลือ
                  <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />

                  ลูกค้าอย่างจริงใจ คือหัวใจสำคัญของการบริการ ซึ่งถือเป็น
                  <Box component="br" sx={{ display: { xs: "none", lg: "block" } }} />

                  เป้าหมายสูงสุดของทาง Ufriend
                </Typography>
              </>
            </Grid>

            <Grid xs={12} md={12} lg={6} xl={6} sx={{ textAlign: { xl: 'start', xs: 'center' } }}>
              <Box
                component="img"
                src={imagesRefinace.UFWEB2_4}
                alt="Service"
                sx={{
                  width: { xs: "100%", md: "58%", lg: '78%', xl: '72%' },
                }}
              />
              <Grid>
                <Box
                  component="img"
                  src={imagesRefinace.UFWEB2_5}
                  alt="Service"
                  sx={{
                    width: { xs: "60%", md: "35%", lg: '45%', xl: '43%' },
                  }}
                />
                <Box
                  component="img"
                  src={imagesRefinace.UFWEB2_6}
                  alt="Service"
                  style={{ objectFit: 'scale-down' }}
                  sx={{
                    width: { xs: "40%", md: "25%", lg: '31%', xl: '29%' },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Style.Container>


        {/* Banner 3 */}

        <Box sx={{ position: "relative" }} ref={sectionRef2}>
          <Box
            component="img"
            src={isMdUp ? imagesRefinace.UFWEB2_7 : imagesRefinace.MUF2_7}
            width="100%"
            alt="Banner 3"
          />
          <Box sx={{
            bottom: { lg: "13%", md: '8%', sm: '5%', xs: '4%' },
            left: { lg: "62%", md: '63%', sm: '24%', xs: '16%' },
            position: "absolute",
          }}>
            <motion.div
              animate={{ x: [-5, 5, -5, 5, 0] }} // Shake effect
              transition={{ duration: 0.7, repeat: Infinity, repeatType: "reverse" }} // Loops forever
            >
              <Button
                variant="contained"
                sx={{
                  // transform: "translateX(-50%)",
                  backgroundColor: "#FFCC00",
                  color: colors.black,
                  fontWeight: "bold",
                  px: 3,
                  py: 1,
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#FFD700",
                  },
                }}
                onClick={() => (navigateToRefinance())}
              >
                <Typography
                  style={{ fontWeight: 700 }}
                  sx={{ typography: { xl: "h4", sm: 'body1', xs: "h6" } }}>
                  ต้องการเงินด่วน <span style={{ color: colors.themeMainColor }}>คลิกเลย</span>
                </Typography>
              </Button>
            </motion.div>
          </Box>

        </Box>

        {/* Section 3: คุณสมบัติ & เอกสาร */}
        <Style.Container>
          <Grid
            pt={4}
            container
          >
            {/* Left Column (Text + Image) */}
            <Grid xs={12} md={12} lg={4}
              sx={{
                textAlign: "center",

              }}
              pl={{ lg: 4, xs: 0 }}
            >
              <Typography color="#333"
                sx={{
                  typography: { lg: 'h4', xl: "h5", md: "h5", xs: "h5" },
                }}
              >
                อยากใช้บริการไอโฟนแลกเงิน
              </Typography>
              <Typography
                style={{ fontWeight: 500, }}
                color={colors.themeMainColor}
                mt={1}
                sx={{
                  typography: { xl: "h4", lg: "h5", md: "h5", xs: "h5" },
                }}
              >
                ต้องมีคุณสมบัติใดบ้าง?
              </Typography>
              <Box
                component="img"
                src={imagesRefinace.UFWEB2_11}
                alt=""
                sx={{
                  width: { xs: "50%", sm: "50%", md: '60%', lg: "85%" },
                  pt: 3,
                }}
              />
            </Grid>

            {/* Card: คุณสมบัติ */}
            <Grid xs={12} md={12} sm="auto" lg={4} display="flex" justifyContent={{ xs: "center", md: "center" }} alignItems="center" pt={{ lg: 0, xs: 3 }}>
              <Card
                sx={{
                  borderRadius: 4,
                  border: `1px solid ${colors.themeMainColor}`,
                  // minWidth: { xs: "100%", sm: "250px" },
                  // maxWidth: 400,
                  minWidth: { lg: '80%', md: "100%", xs: "100%" },
                  maxWidth: { xl: '85%', md: "100%", xs: "100%" },
                  justifyContent: 'center',
                  minHeight: { xl: '275px', lg: '275px', sm: '275px' }
                }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.themeMainColor,
                    color: "#fff",
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{ fontWeight: 500, }}
                    sx={{
                      typography: { xl: "h5", lg: "h5", md: "h5", xs: "h5" },
                    }}>
                    คุณสมบัติ
                  </Typography>
                </Box>
                <CardContent>
                  <List>
                    {qualifications.map((item, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemIcon sx={{ minWidth: 28 }}>
                          <CheckCircleIcon sx={{ color: colors.yellow }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: { xl: "20px", lg: "18px", md: "18px", xs: "16px" },
                          }}
                          primary={item}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>

            {/* Card: เอกสารที่ต้องใช้ */}
            <Grid xs={12} md={12} sm="auto" lg={4} display="flex" justifyContent={{ xs: "center", md: "center" }} alignItems="center" pt={{ lg: 0, xs: 3 }}>
              <Card
                sx={{
                  borderRadius: 4,
                  border: `1px solid ${colors.themeMainColor}`,
                  // minWidth: { xs: "100%", sm: "250px" },
                  // maxWidth: 400,
                  minWidth: { lg: '80%', md: "100%", xs: "100%" },
                  maxWidth: { xl: '85%', md: "100%", xs: "100%" },
                  justifyContent: 'center',
                  minHeight: { xl: '275px', lg: '275px', sm: '275px' }
                }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.themeMainColor,
                    color: "#fff",
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography style={{ fontWeight: 500, }}
                    sx={{
                      typography: { xl: "h5", lg: "h5", md: "h5", xs: "h5" },
                    }}>
                    เอกสารที่ต้องใช้
                  </Typography>
                </Box>
                <CardContent>
                  <List>
                    {documents.map((item, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemIcon sx={{ minWidth: 28 }}>
                          <CheckCircleIcon sx={{ color: colors.yellow }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: { xl: "20px", lg: "18px", md: "18px", xs: "16px" },
                          }}
                          primary={item}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Style.Container>


        {/* Section 4: ติดต่อสอบถามแอดมิน */}
        <Style.Container>
          <Box>
            <Grid container>
              {/* Left Image */}
              <Grid xs={12} md={12} lg={6} display="flex" justifyContent="center" alignItems="center" >
                <Box
                  component="img"
                  src={isMdUp ? imagesRefinace.UFWEB2_8 : imagesRefinace.MUF2_8}
                  alt="Contact"
                  sx={{
                    width: { xs: "100%", md: "70%", xl: "100%" },
                    // marginLeft: { xl: "22%" }
                  }}
                />
              </Grid>

              {/* Right Content */}
              <Grid xs={12} md={12} lg={6}
                pt={{ md: 7 }}
              >
                <Box sx={{
                  textAlign: { lg: 'left', xs: "center" }
                }}>
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: colors.themeMainColor,
                        padding: "10px 20px",
                        borderRadius: 5,
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        whiteSpace: 'nowrap'
                      }}
                      onClick={() => (navigateToRefinance())}
                    >
                      <Typography
                        style={{ fontWeight: 700 }}
                        sx={{
                          typography: { xl: "h3", lg: "h3", md: "h3", xs: "h6" }
                        }}>
                        <span style={{ paddingRight: 5, color: colors.yellow }}>สมัครแลกเงินด่วน</span>{" "}
                        <span style={{ color: colors.yellow }}>คลิก</span>
                      </Typography>
                    </Button>
                  </motion.div>
                </Box>

                <Grid container >
                  <Grid item lg={6} xs={6} display="flex" justifyContent={{ xs: "center", md: "center", lg: 'left' }} alignItems="center">
                    <Box
                      component="img"
                      src={imagesRefinace.UFWEB2_12}
                      alt=""
                      sx={{
                        width: { xl: '88%', md: "88%", xs: "100%" },
                        pt: { xl: 3, xs: 3, md: 5, lg: 4 },
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={6} >
                    <Typography
                      lineHeight={1.2}
                      style={{ fontWeight: 500 }}
                      sx={{
                        typography: { xl: "h4", lg: "h4", md: "h4", xs: "body1" },
                        pt: { xl: 4, xs: 3, md: 7, lg: 4 },
                        pl: { xl: 0, xs: 3, md: 6, lg: 4 },
                        textAlign: { xs: 'left', md: 'center', lg: 'left' }
                      }}
                    >
                      รับแลกเงินทุกที่
                      <br />
                    </Typography>
                    <Typography
                      lineHeight={1.2}
                      className="pt-2"
                      style={{ fontWeight: 500 }}
                      sx={{
                        typography: { xl: "h5", lg: "h5", md: "h5", xs: "body1" },
                        pl: { xl: 0, xs: 3, md: 6, lg: 4 },
                        textAlign: { xs: 'left', md: 'center', lg: 'left' }
                      }}
                    >
                      ยินดีบริการทั่วประเทศ
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  lineHeight={2.0}
                  className="pt-4"
                  variant="h6"
                  style={{ fontWeight: 100, lineHeight: 1.8 }}
                  sx={{
                    maxWidth: "100%",
                    typography: { xl: "h5", lg: "body1", md: "body1", sx: 'body2', xs: "caption" },
                    whiteSpace: { xl: 'nowrap', xs: 'normal' }
                  }}
                >
                  Ufriend เรามีสาขาหน้าร้านให้บริการไอโฟนแลกเงินกว่า 10 แห่งทั่วประเทศ
                  <Box component="br" sx={{ display: { xs: "none", xl: "block" } }} />
                  เราพร้อมให้บริการคุณทุกที่ ทุกเวลา และเรามีแผนขยายสาขาเพิ่มขึ้นอย่าง
                  <Box component="br" sx={{ display: { xs: "none", xl: "block" } }} />
                  ต่อเนื่องพร้อมระบบชำระเงินผ่านแอปที่ทันสมัย ใช้งานง่าย ตรวจสอบได้
                  <Box component="br" sx={{ display: { xs: "none", xl: "block" } }} />
                  แบบ Real-Time เพื่อความสะดวกรวดเร็วทันใจ ตอบโจทย์ได้ทุกความต้องการ
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Style.Container>


        {/* Branch */}
        <Branch />
        <div ref={sectionRef3}>

        </div>
        {/* รีวิว */}
        <Box sx={{ textAlign: "center", my: { md: 3 } }} >
          <Typography variant="h4" fontWeight={400}
          // sx={{
          //   typography: { xl: "h4", lg: "h5", md: "h5", xs: "h5" }
          // }}
          >
            รีวิวบางส่วนจากลูกค้าจริง
          </Typography>
          <Typography variant="h4" fontWeight={400}
          // sx={{
          //   typography: { xl: "h5", lg: "h6", md: "h6", xs: "h6" }
          // }}
          >
            ที่ใช้บริการไอโฟนแลกเงิน{" "}
            <Box component="span" sx={{ color: colors.themeMainColor }}>
              Ufriend
            </Box>
          </Typography>
          <Box
            component="img"
            src={isMdUp ? imagesRefinace.UFWEB2_9 : imagesRefinace.MUF2_9}
            alt="Review Section"
            sx={{
              width: "80%",
              mt: 2,
            }}
          />
        </Box>

        <Review type="REFINANCE" />

        <Style.Container >
          <Grid container>
            {/* Left Text */}
            <Grid xs={12} md={7}>
              <Typography
                style={{ fontWeight: 400 }}
                sx={{
                  typography: { xl: "h4", lg: "h5", md: "h5", xs: "h6" }, textAlign: { md: 'left', xs: 'center' }
                }}
              >
                หากคุณต้องการเงินฉุกเฉิน
                <Box component="br" sx={{ display: { xs: "block", md: "none" } }} />
                ไม่ต้องลังเล!!{" "}
                <Box component="br" sx={{ display: { xs: "none", xl: "block" } }} />
                ที่{" "}
                <Box component="span" sx={{ color: colors.themeMainColor, fontWeight: 500 }}>
                  Ufriend {" "}
                </Box>
                <Box component="br" sx={{ display: { xs: "block", md: "none" } }} />
                เรายินดีช่วยคุณก้าวผ่าน
                <Box component="br" sx={{ display: { xs: "block", md: "none" } }} />
                ปัญหาเรื่องการเงิน
              </Typography>
            </Grid>

            {/* Right Button */}
            <Grid xs={12} md={5} sx={{ textAlign: "center" }} pt={{ xs: 2, md: 0 }} pb={{ xs: 2, md: 0 }}>
              <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: [1, 1.1, 1] }} // กระเด้งไปมา
                transition={{ duration: 0.8, repeat: Infinity, repeatType: "reverse" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.themeMainColor,
                    color: colors.yellow,
                    fontWeight: 500,
                    padding: "10px 20px",
                    borderRadius: "30px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => (navigateToRefinance())}
                >
                  <Typography
                    style={{ fontWeight: 700 }}
                    sx={{ typography: { xl: "h3", lg: "h6", md: "h6", xs: "h6" } }}
                  >
                    สมัครแลกเงินด่วน คลิก
                  </Typography>
                </Button>
              </motion.div>

            </Grid>
          </Grid>
        </Style.Container>
      </div >
      <div style={{ position: 'fixed', width: '48px', height: '48px', zIndex: 1, cursor: 'pointer', bottom: '40px', right: '15px' }}>
        <Box
          component="img"
          src={imgs.lineApp}
          alt=""
          sx={{ width: "100%" }}
          onClick={() => { navigateToLine() }}
        />
      </div >
      <div ref={sectionRef4}>
        <Footer2 type="REFINANCE" />
      </div>
    </>
  );
}

