import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs
} from "@mui/material";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  useLoadScript
} from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AuthenApi from "../../api/authen/authen.api";
import GoogleMapApi from "../../api/google-map/googleMap";
import { CardLocation } from "../../components/card/card";
import InputTextField from "../../components/input/inputTextField";
import { swalError } from "../../components/notification/swal";
import { colors } from "../../constants/colors";
import "../../styles/googleMap.css";
import * as Style from "./branch.style";

export default function Branch() {
  const [mapRef, setMapRef] = useState<google.maps.Map | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState({
    id: 0,
    address: "",
    location_url: "",
  });
  const [optionBranchLocation, setOptionBranchLocation] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(10);
  const [valueSeach, setValueSearch] = useState<string>("");
  const infoData = useRef({ id: 0, address: "" });
  const [tab, setTab] = useState<string>("กรุงเทพและปริมณฑล");
  const [tabValue, setTabValue] = useState<string>("BKK");
  const [cardData, setCardData] = useState<any>();
  const [markers, setMarkers] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-5XYOoU_k99QzqMX7C9WhvcVCKOPFU6k",
  });

  const onFetchData = useCallback(async () => {
    const condition: any = {
      type: tab === "กรุงเทพและปริมณฑล" ? "BKK" : "OTHER",
    };
    const res = await AuthenApi.cardLocation(condition);

    if (res.status === 200) {
      setCardData(res.data.result);
    }
  }, [tabValue, tab]);

  useEffect(() => {
    onFetchBranchLocation();
    onFetchBranchRegion("BKK");
  }, []);

  useEffect(() => {
    if (tab === "กรุงเทพและปริมณฑล") {
      setTabValue("BKK");
      onFetchData();
    }
    if (tab === "ต่างจังหวัด") {
      setTabValue("OTHER");
      onFetchData();
    }
  }, [tab]);

  useEffect(() => {
    var filteredData = onSearch(optionBranchLocation);
    setFilteredList(filteredData);
  }, [valueSeach]);

  const onSearch = (filteredData: any) => {
    if (!valueSeach) {
      return filteredData;
    }

    const filtered = filteredData.filter(
      (el: any) =>
        el.value.toLowerCase().indexOf(valueSeach.toLowerCase()) !== -1
    );
    return filtered;
  };

  const cutString = (value: string) => {
    let result = "";
    if (value.includes("Apple House")) {
      let cut = value.split("Apple House ");
      result = cut[1];
    } else if (value.includes("Future Park")) {
      let cut = value.split("Future Park ");
      result = cut[1];
    } else {
      result = value;
    }
    return result;
  };
  const onFetchBranchLocation = async () => {
    try {
      let obj = {
        branchName: "",
      };
      const res = await GoogleMapApi.getLocation(obj);
      const { success, result } = res.data;
      if (success) {
        const data = result
          .filter(
            (el: any) =>
              el.value !== "ON_HOLD" &&
              el.value !== "IT" &&
              el.value !== "QC" &&
              el.value !== "Store" &&
              el.value !== "Service" &&
              el.value !== "Refurbished"
          )
          .filter(
            (el: any) => el.location.lat !== null && el.location.long !== null
          )
          .map((el: any, i: number) => {
            return {
              ...el,
              true_value: el.value,
              value: el.label,
              index: i,
              title: `Ufriend สาขา ${cutString(el.label)}`,
              address: `สาขา ${el.label}`,
              location_url: el.location.location_url || "",
              lat: Number(el.location.lat) || "",
              lng: Number(el.location.long) || "",
            };
          });

        const markerLocation = data
          .filter((el: any) => JSON.stringify(el.location) !== "{}")
          .filter(
            (el: any) => JSON.stringify(el.location.location_url) !== null
          )
          .map((el: any) => {
            return {
              lat: Number(el.location.lat) || "",
              lng: Number(el.location.long) || "",
              place: el.true_value,
              type: el.type || "",
              location_url: el.location.location_url || "",
              address: `สาขา ${el.label}`,
            };
          });
        setOptionBranchLocation(data);
        setFilteredList(data);
        setMarkers(markerLocation);
      }
    } catch (error: any) {
      swalError(error.message);
    } finally {
    }
  };

  const onFetchBranchRegion = async (value: string) => {
    try {
      let obj = {
        type: value || "",
      };
      const res = await GoogleMapApi.getRegion(obj);
      const { success, result } = res;
      if (success) {
        const data = result
          .filter(
            (el: any) =>
              el.value !== "ON_HOLD" &&
              el.value !== "IT" &&
              el.value !== "QC" &&
              el.value !== "Store" &&
              el.value !== "Service" &&
              el.value !== "Refurbished"
          )
          .filter((el: any) => el.location.image_url !== null)
          .map((el: any) => {
            return {
              ...el,
              origin_label: el.label,
              label: cutString(el.label),
            };
          });

        let arr = data;
        let tempArr = [];
        let arr2 = [];
        let index = 0;
        while (index < arr.length) {
          tempArr = [];
          for (let i = index; i < index + 4; i++) {
            if (arr[i] !== undefined) {
              tempArr.push(arr[i]);
            }
          }
          arr2.push(tempArr);
          index += 4;
        }
      }
    } catch (error: any) {
      swalError(error.message);
    } 
  };

  const onMapLoad = (map: any) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();

    const HQ = markers.filter((el: any) => el.type === "BKK");
    HQ.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const translateBranchMarkerName = (value: string) => {
    let result = "";
    if (value.includes("Apple House")) {
      let cut = value.split("Apple House ");
      result = `สาขา ${cut[1]}`;
    } else if (value.includes("Future Park")) {
      let cut = value.split("Future Park ");
      result = `สาขา ${cut[1]}`;
    } else {
      result = value;
    }
    return result;
  };

  const handleMarkerClick = (
    id: number,
    lat: number,
    lng: number,
    url: string,
    address: string
  ) => {
    try {
      mapRef?.panTo({ lat, lng });
      setInfoWindowData({
        id: id,
        address: translateBranchMarkerName(address),
        location_url: url,
      });
      infoData.current.address = address;
      infoData.current.id = id;
      setZoomLevel(15);
      setIsOpen(true);
    } catch (error: any) {

    }
  };

  return (
    <Style.Container>
      <Row>
        <Style.HeaderText style={{}}>
          ค้นหาสาขาใกล้คุณ
        </Style.HeaderText>
      </Row>
      <Row style={{ marginTop: 16, marginBottom: 80, }}>
        <Col lg={6} xs={12}>
          <InputTextField
            placeholder="ค้นหา"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={valueSeach}
            onchange={(e: any) => setValueSearch(e.target.value)}
            style={{ display: { xs: "inline-flex", md: "none" }, marginBottom: 2 }}
          />
          {isLoaded && (
            <GoogleMap
              mapContainerClassName="mapContainer"
              onLoad={onMapLoad}
              options={{ streetViewControl: false }}
              zoom={zoomLevel}
              center={{ lat: 13.695144, lng: 100.536017 }}
            >
              {markers.map(({ address, lat, lng, location_url }, ind) => (
                <Marker
                  key={ind}
                  position={{ lat, lng }}
                  onClick={() => {
                    handleMarkerClick(ind, lat, lng, location_url, address);
                  }}
                >
                  {isOpen && infoWindowData.id === ind && (
                    <InfoWindowF
                      onCloseClick={() => {
                        setZoomLevel(10);
                        setIsOpen(false);
                      }}
                    >
                      <Link
                        href={`${infoWindowData.location_url}`}
                        target="_blank"
                        underline="none"
                        color={colors.black}
                      >
                        <h5>{infoWindowData.address}</h5>
                      </Link>
                    </InfoWindowF>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          )}
        </Col>
        <Col lg={6} xs={12} >
          <InputTextField
            placeholder="ค้นหา"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={valueSeach}
            onchange={(e: any) => setValueSearch(e.target.value)}
            style={{ display: { xs: "none", md: "inline-flex" }, width: "100%" }}
          />
          <List
            sx={{
              overflow: "auto",
              border: "1px solid #e1e1e1",
              borderRadius: 3,
              marginTop: 2,
              height: "460px",
            }}
          >
            {filteredList.map((el: any) => {
              return (
                <ListItemButton
                  onClick={() =>
                    handleMarkerClick(
                      el.index,
                      el.lat,
                      el.lng,
                      el.location_url,
                      el.address
                    )
                  }
                >
                  <ListItemText primary={el.title} />
                </ListItemButton>
              );
            })}
          </List>
        </Col>
      </Row>

      <div>
        <Style.HeaderText style={{ marginTop: 16 }}>
          เปิดบริการทั่วประเทศ
        </Style.HeaderText>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={(e: any, newValue: string) => setTab(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="กรุงเทพและปริมณฑล" value={"กรุงเทพและปริมณฑล"} />
          <Tab label="ต่างจังหวัด" value={"ต่างจังหวัด"} />
        </Tabs>
      </Box>

      {tab === "กรุงเทพและปริมณฑล" && (
        <>
          <Row>
            {cardData?.map((d: any) => {
              return (
                <Col lg={3} md={4} sm={6} xs={12}>
                  <CardLocation item={d} />
                </Col>
              );
            })}
          </Row>
        </>
      )}

      {tab === "ต่างจังหวัด" && (
        <>
          <Row>
            {cardData?.map((d: any) => {
              return (
                <Col lg={3} md={4} sm={6} xs={12}>
                  <CardLocation item={d} />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </Style.Container>
  );
}
