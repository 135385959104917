import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import AuthenUploadApi from '../../../api/authen/authenUpload.api';
import ButtonCustom from '../../../components/button/buttonCustom';
import InputTextField from '../../../components/input/inputTextField';
import ModalCustom from '../../../components/modalCustom/modalCustom';
import AutocompleteSelect from '../../../components/select/autoCompleteSelect';
import UploadImageList from '../../../components/upload/list';
import UploadImage from '../../../components/upload/uploadFile';
import { colors } from '../../../constants/colors';
import { checkEngLang, checkPattenPhoneNumber, checkThaiLang, chkDigitPid } from '../../../utils/common';
import { getAgeMonth } from '../../../utils/date.utils';
import { optionsPhoneSystem, optionsPrefix } from '../../../utils/options';
import { fromRegister } from '../type-register';
import * as Styles from './layout-personal.style';
import { amplitudeTrack } from '../../../utils/amplitude/amplitude';
import OptionsApi from '../../../api/option/option.api';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useWindowSize from '../../../utils/useWindowSize';
const initStateErrorMessage = {
    PERSONAL_PREFIX: ``,
    PERSONAL_OTHERPREFIX: ``,
    PERSONAL_NAME: ``,
    PERSONAL_LASTNAME: ``,
    PERSONAL_IDCARD: ``,
    PERSONAL_DATEOFBIRTH: ``,
    PERSONAL_PHONENUMBER: ``,
    PERSONAL_ADDRESS: ``,
    PERSONAL_ZIPCODE: ``,
    PERSONAL_PROVINCE: ``,
    PERSONAL_DISTRICT: ``,
    PERSONAL_SUBDISTRICT: ``,
    PERSONAL_FACEBOOK: ``,
    PERSONAL_PHONESYSTEM: ``,
    PERSONAL_SERIALNUMBER: ``,
    PERSONAL_SERIALNUMBERPICTURE: ``,
    PERSONAL_STORAGEPICTURE: ``
}

const initLoading = {
    serialNumberImg: false,
    storageImg: false
}

interface props {
    onClickNext: () => void;
    onClickBack: () => void;
    data: any
    onChange?: (value: any) => void
    optionMaster: any
}
export default function LayoutPersonal(props: props) {
    const [width, height] = useWindowSize()
    const [data, setData] = useState<fromRegister>(props.data)
    const [imgFacebook, setImgFacebook] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
    const [loadingImg, setLoadingImg] = useState(initLoading)
    const [prefix, setPrefix] = useState<string>(props.data?.profile?.prefix === 'นาย'
        || props.data?.profile?.prefix === 'นาง' || props.data?.profile?.prefix === 'นางสาว'
        || props.data?.profile?.prefix === '' || props.data?.profile?.prefix === undefined ? props.data?.profile?.prefix : 'อื่นๆ')
    const [prefixOther, setPrefixOther] = useState<string>(props.data?.profile?.prefix || '')
    const [firstname, setFirstName] = useState<string>(props.data?.profile?.firstName || '')
    const [lastname, setLastname] = useState<string>(props.data?.profile?.lastName || '')
    const [idCard, setIdCard] = useState<string>(props.data?.profile?.idCard || '')
    const [birthday, setBirthday] = useState<string>(props.data?.profile?.dateOfBirth || '')
    const [birthdayFlag, setBirthdayFlag] = useState<boolean>(true)
    const [convertBirthday, setConvertBirthday] = useState<string>('')
    const [age, setAge] = useState<string>("")
    const [ageAlert, setAgeAlert] = useState<boolean>(false)
    const [ageAlertInvalid, setAgeAlertInvalid] = useState<boolean>(false)

    const [phoneNumber, setPhoneNumber] = useState<string>(props.data?.profile?.phoneNumber || '')
    const [address, setAddress] = useState<string>(props.data?.profile?.address || '')
    const [zipCode, setZipCode] = useState<string>(props.data?.profile?.zipcode || '')
    const [province, setProvince] = useState<string>(props.data?.profile?.province || '')
    const [district, setDistrict] = useState<string>(props.data?.profile?.district || '')
    const [subdistrict, setSubdistrict] = useState<string>(props.data?.profile?.subdistrict || '')
    const [facebook, setFacebook] = useState<string>(props.data?.profile?.facebook || '')
    const [phoneSystem, setPhoneSystem] = useState<string>(props.data?.profile?.phoneSystem || '')
    const [optionProvice, setOptionProvice] = useState<any[]>([])
    const [optionProviceMaster, setOptionProviceMaster] = useState<any[]>([])
    const [optionDistrict, setOptionDistrict] = useState<any[]>([])
    const [optionDistrictMaster, setOptionDistrictMaster] = useState<any[]>([])
    const [optionSubDistrict, setOptionSubdistrict] = useState<any[]>([])

    const [serialNumber, setSerialNumber] = useState<string>(props.data?.profile?.serial_number)
    const [imeiImages, setImeiImages] = useState<any>(props.data?.profile?.imeiImages)
    const [iphoneStorage, setPhoneStorage] = useState<any>(props.data?.profile?.iphoneStorage)
    const [checkPhoneNumber, setCheckPhonenumber] = useState<boolean>(false)
    const [checkIdCard, setCheckIdCard] = useState<boolean>(false)

    const [checkDigiPhoneNumber, setCheckDigiPhonenumber] = useState<boolean>(false)
    const [checkZipCode, setCheckZipcode] = useState<boolean>(false)

    const [isShowModalFacebook, setIsShowMoalFacebook] = useState<boolean>(false)

    const [checkIsShowModalFacebook, setCheckIsShowModalFacebook] = useState<boolean>(false)
    const [checkFacebook, setCheckFacebook] = useState<boolean>(false)

    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const PARAMS_IDCARD = params.get('iDCard');

    const [openTooltip, setOpenTooltipValue] = useState<string>('');
    const [disableButtonNext, setDisableButtonNext] = useState<boolean>(false);


    useEffect(() => {
        if (isShowModalFacebook) {
            setCheckIsShowModalFacebook(true)
        }
    }, [isShowModalFacebook]);

    useEffect(() => {
        if (PARAMS_IDCARD && !props.data?.profile?.idCard) {
            setIdCard(PARAMS_IDCARD || '')
        }
    }, [PARAMS_IDCARD]);

    const loadFacebookExample = () => {
        OptionsApi.facebookExample().then((res) => {
            if (res.status === 200) {
                setImgFacebook(res.data.result)
            }
        })
    }

    useEffect(() => {
        loadFacebookExample()
    }, []);

    useEffect(() => {
        if (props.onChange) props.onChange(data)
    }, [data])

    useEffect(() => {
        if (zipCode?.length === 5 && props.optionMaster.length > 0) {
            const filterProvinceMaster = props.optionMaster.filter((val: any) => val.zipcode === zipCode)
                .map((data: any) => {
                    return data;
                });
            const filterProvince = props.optionMaster.filter((val: any) => val.zipcode === zipCode)
                .map((data: any) => {
                    return { value: data.province, label: data.province };
                });
            if (filterProvinceMaster.length === 0) {
                setCheckZipcode(true)
            }
            // amplitudeTrack(`${'2_Profile_P_zipcode-' + zipCode}`, zipCode)
            setOptionProviceMaster(filterProvinceMaster)
            setOptionProvice(duplicate(filterProvince))
        } else {
            setOptionProviceMaster([])
            setOptionProvice([])
            setOptionDistrictMaster([])
            setOptionDistrict([])
            setOptionSubdistrict([])
            setProvince('')
            setDistrict('')
            setSubdistrict('')
        }
    }, [zipCode]);

    useEffect(() => {
        if (province) {
            const filterDistrictMaster = optionProviceMaster.filter((val: any) => val.province === province)
                .map((data: any) => {
                    return data;
                });
            const filterDistrict = optionProviceMaster.filter((val: any) => val.province === province)
                .map((data: any) => {
                    return { value: data.amphoe, label: data.amphoe };
                });
            setOptionDistrictMaster(filterDistrictMaster)
            setOptionDistrict(duplicate(filterDistrict))
        } else {
            setOptionSubdistrict([])
            setDistrict('')
            setSubdistrict('')
        }

    }, [province, optionProviceMaster]);

    useEffect(() => {
        if (district) {
            const filterSubdistrict = optionDistrictMaster.filter((val: any) => val.amphoe === district)
                .map((data: any) => {
                    return { value: data.district, label: data.district };
                });
            setOptionSubdistrict(duplicate(filterSubdistrict))
        } else {
            setOptionSubdistrict([])
            setSubdistrict('')
        }
    }, [district, optionDistrictMaster]);


    useEffect(() => {
        if (birthday && birthdayFlag && props.data?.profile?.dateOfBirth) {
            const dob = moment(birthday, 'MM/DD/YYYY').add(543, 'years').format('DD/MM/YYYY')
            setBirthday(dob)
            setBirthdayFlag(false)
            setConvertBirthday(props.data?.profile?.dateOfBirth)
        } else {
            if (birthday.length === 10) {
                const age = getAgeMonth(birthday)
                if (age.years > 0 && age.months >= 0) {
                    setAge(`${age.years} ปี ${age.months} เดือน`)
                    if (age.years > 70 || age.years < 15) {
                        setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: 'ผู้ทำสัญญาต้องมีอายุระหว่าง 20-60 ปี' })
                        setAgeAlert(true)
                    } else {
                        setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: '' })
                        setAgeAlert(false)
                    }

                    setAgeAlertInvalid(false)
                } else {
                    setAge('0 ปี 0 เดือน')
                    setAgeAlert(false)
                    setAgeAlertInvalid(true)
                    if (birthday) {
                        setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: 'วัน/เดือน/ปีเกิด ไม่ถูกต้อง' })
                    }
                }
            } else {
                if (birthday.length !== 10)
                    setAgeAlertInvalid(true)
            }
        }
    }, [birthday]);

    useEffect(() => {
        if (idCard && idCard.length === 13) {
            const check = chkDigitPid(idCard)
            if (!check) {
                setCheckIdCard(true)
                const msg: any = {
                    PERSONAL_IDCARD: !check ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
                }
                setErrorMessage({ ...errorMessage, ...msg })
            } else {
                setCheckIdCard(false)
            }
        }
    }, [idCard]);

    const handleChangeDate = (event: any) => {
        if (event.target.value.length <= 10) {
            let inputDate = event.target.value;
            inputDate = inputDate.replace(/\D/g, '');
            if (inputDate.length <= 2) {
                inputDate = inputDate.replace(/^(\d{0,2})/, '$1');
            } else if (inputDate.length <= 4) {
                inputDate = inputDate.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
            } else {
                inputDate = inputDate.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3').slice(0, 10);
            }
            setBirthday(inputDate);
            const convertDob = moment(inputDate, 'DD/MM/YYYY').subtract(543, 'years').format('MM/DD/YYYY')
            setConvertBirthday(convertDob)
            setAge('0 ปี 0 เดือน')
            setErrorMessage({ ...errorMessage, PERSONAL_DATEOFBIRTH: '' })
        }
    };

    const duplicate = (data: any) => {
        if (data.length > 0) {
            return data.filter(
                (el: any, id: number) => id === data.findIndex((ele: any) => ele.value === el.value)
            );
        }
    }

    useEffect(() => {
        if (phoneNumber && phoneNumber.length === 10) {
            const check = checkPattenPhoneNumber(phoneNumber)
            setCheckPhonenumber(!check)
            setCheckDigiPhonenumber(false)
        } else {
            setCheckDigiPhonenumber(true)
        }
    }, [phoneNumber]);

    useEffect(() => {
        const allFalse = Object.entries(loadingImg).every(([key, value]) => value === false);
        if (!allFalse) {
            setDisableButtonNext(true)
        } else {
            setDisableButtonNext(false)
        }
    }, [loadingImg]);
    const onSubmit = async () => {
        const allFalse = Object.entries(loadingImg).every(([key, value]) => value === false);
        if (!allFalse) {
            const msg: any = {
                PERSONAL_SERIALNUMBERPICTURE: loadingImg.serialNumberImg ? 'กำลังอัพโหลดไฟล์ กรุณารอสักครู่' : '',
                PERSONAL_STORAGEPICTURE: loadingImg.storageImg ? 'กำลังอัพโหลดไฟล์ กรุณารอสักครู่' : '',
            }
            setErrorMessage({ ...initStateErrorMessage, ...msg })
            return false
        }
        if (!prefix || !firstname || !lastname || !idCard || idCard.length < 13 || !birthday || !phoneNumber
            || !address || !province || !district || !subdistrict || !zipCode || !facebook
            || !phoneSystem || checkPhoneNumber || checkDigiPhoneNumber ||
            (props.data?.type === 'REFINANCE' && (!serialNumber) || checkZipCode) ||
            (prefix === 'อื่นๆ' && !prefixOther) ||
            (iphoneStorage?.images.length === 0 && props.data?.type === 'REFINANCE') || (imeiImages?.images.length === 0 && props.data?.type === 'REFINANCE') ||
            (!imeiImages && props.data?.type === 'REFINANCE') || (!iphoneStorage && props.data?.type === 'REFINANCE') || checkIdCard || ageAlert || ageAlertInvalid
        ) {
            const msg: any = {
                PERSONAL_PREFIX: !prefix ? 'โปรดเลือกคำนำหน้าชื่อ' : '',
                PERSONAL_NAME: !firstname ? 'โปรดกรอกชื่อ' : '',
                PERSONAL_LASTNAME: !lastname ? 'โปรดกรอกนามสกุล' : '',
                PERSONAL_IDCARD: !idCard ? 'โปรดกรอกรหัสบัตรประชาชน' : idCard.length < 13 ? 'โปรดกรอกรหัสบัตรประชาชนให้ครบ 13 หลัก' : checkIdCard ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
                PERSONAL_DATEOFBIRTH: !birthday ? 'โปรดกรอกวัน/เดือน/ปีเกิด' : ageAlertInvalid ? 'วัน/เดือน/ปีเกิด ไม่ถูกต้อง' : ageAlert ? 'ผู้ทำสัญญาต้องมีอายุระหว่าง 20-60 ปี' : ageAlertInvalid ? 'วัน/เดือน/ปีเกิด ไม่ถูกต้อง' : '',
                PERSONAL_PHONENUMBER: !phoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์' : checkPhoneNumber ? 'โปรดกรอกเบอร์โทรศัพท์ให้ถูกต้อง' : checkDigiPhoneNumber ? 'ระบุจำนวน 10 ตัวอักษร' : '',
                PERSONAL_ADDRESS: !address ? 'โปรดกรอกที่อยู่' : '',
                PERSONAL_PROVINCE: !province ? 'โปรดเลือกจังหวัด' : '',
                PERSONAL_DISTRICT: !district ? 'โปรดเลือกเขต/อำเภอ' : '',
                PERSONAL_SUBDISTRICT: !subdistrict ? 'โปรดเลือกแขวง/ตำบล' : '',
                PERSONAL_ZIPCODE: !zipCode ? 'โปรดกรอกรหัสไปรษณีย์' : checkZipCode ? 'รหัสไปรษณีย์ไม่ถูกต้อง' : '',
                PERSONAL_FACEBOOK: !facebook ? 'โปรดกรอก Facebook' : '',
                PERSONAL_PHONESYSTEM: !phoneSystem ? 'โปรดเลือกระบบโทรศัพท์มือถือ' : '',
                PERSONAL_SERIALNUMBER: !serialNumber ? 'โปรดกรอกเลขประจำเครื่อง (Serial)' : '',
                PERSONAL_OTHERPREFIX: !prefixOther ? 'โปรดกรอกคำนำหน้าชื่อ' : '',
                PERSONAL_SERIALNUMBERPICTURE: (imeiImages?.images.length === 0 && props.data?.type === 'REFINANCE') || (!imeiImages && props.data?.type === 'REFINANCE') ? 'โปรดอัพโหลดรูปเลขประจำเครื่อง (Serial)' : '',
                PERSONAL_STORAGEPICTURE: (iphoneStorage?.images.length === 0 && props.data?.type === 'REFINANCE') || (!iphoneStorage && props.data?.type === 'REFINANCE') ? `โปรดอัพโหลดรูป ${props.data?.productGroup || ''} Storage` : ''
            }
            setErrorMessage({ ...initStateErrorMessage, ...msg })
            return false
        } else {
            // amplitudeTrack('2_Profile_N_next')
            await setData({
                ...data,
                profile: {
                    ...data.profile,
                    idCard: idCard, //เลขบัตรปชช
                    phoneNumber: phoneNumber, // เบอร์โทรศัพท์
                    prefix: prefix === 'อื่นๆ' ? prefixOther : prefix, //  คำนำหน้าชื่อ
                    firstName: firstname, // ชื่อ 
                    lastName: lastname, // นามสกุล
                    address: address, //
                    zipcode: zipCode, // รหัสไปรษณีย์ที่อยู่ปัจจุบัน
                    subdistrict: subdistrict, // แขวง
                    district: district, // เขต
                    province: province, // จังหวัด
                    facebook: facebook, // facebook
                    phoneSystem: phoneSystem, // ท่านใช้ระบบโทรศัพท์มือถือแบบใด
                    dateOfBirth: convertBirthday,
                    // dateOfBirth: moment(convertBirthday).format('YYYY-MM-DD'), // วันเกิด
                    serial_number: serialNumber,
                    imeiImages: imeiImages,
                    iphoneStorage: iphoneStorage
                },
            })
            amplitudeTrack('2_Profile_N_next')
            setTimeout(() => {
                return props.onClickNext && props.onClickNext()
            }, 500);
        }

    }


    const onClickBack = async () => {
        // amplitudeTrack('2_Profile_B_back')
        await setData({
            ...data,
            profile: {
                ...data.profile,
                idCard: idCard, //เลขบัตรปชช
                phoneNumber: phoneNumber, // เบอร์โทรศัพท์
                prefix: prefix === 'อื่นๆ' ? prefixOther : prefix, //  คำนำหน้าชื่อ
                firstName: firstname, // ชื่อ 
                lastName: lastname, // นามสกุล
                address: address, //
                zipcode: zipCode, // รหัสไปรษณีย์ที่อยู่ปัจจุบัน
                subdistrict: subdistrict, // แขวง
                district: district, // เขต
                province: province, // จังหวัด
                facebook: facebook, // facebook
                phoneSystem: phoneSystem, // ท่านใช้ระบบโทรศัพท์มือถือแบบใด
                dateOfBirth: convertBirthday, // วันเกิด
                serial_number: serialNumber,
                imeiImages: imeiImages,
                iphoneStorage: iphoneStorage
            },
        })
        amplitudeTrack('2_Profile_B_back')
        setTimeout(() => {
            return props.onClickBack && props.onClickBack()
        }, 500);
    }

    const onUpload = (event: any, type: string) => {
        if (event.target.files[0]) {
            fromData(event.target.files[0], type)
        }
    }

    const fromData = async (image: any, type: string) => {
        const formData = new FormData();
        formData.append("image", image);
        formData.append("bucket", `${process.env.REACT_APP_GCS_BUCKET_REGISTER}`);
        formData.append("subDir", `${process.env.REACT_APP_GCS_PATH_REGISTER}`);

        if (type === 'STORAGE') {
            setLoadingImg({ ...loadingImg, ...{ storageImg: true } })
            setPhoneStorage(
                {
                    images: [{
                        name: image.name,
                        img: URL.createObjectURL(image)
                    }]
                }
            )
        } else {
            setLoadingImg({ ...loadingImg, ...{ serialNumberImg: true } })
            setImeiImages(
                {
                    images: [{
                        name: image.name,
                        img: URL.createObjectURL(image)
                    }]
                }
            )
        }

        await AuthenUploadApi.registerUpload(formData).then((res: any) => {
            if (res.status === 200) {
                const fileName = res?.data?.url[0]?.filename
                const fileNameOrigin = res?.data?.url[0]?.fileNameOrigin
                const mimeType = res?.data?.url[0]?.mimetype 
                const url = res?.data?.url[0]?.url
                
                if (type === 'STORAGE') {
                    setPhoneStorage({
                        ...iphoneStorage, type: "REFINANCE",
                        images: [{
                            fileName: fileName,
                            fileNameOrigin: fileNameOrigin,
                            mimeType: mimeType,
                            url: url,
                            name: image.name,
                            img: URL.createObjectURL(image)
                        }]
                    })
                    // setPreviewStorage({ name: image.name, img: URL.createObjectURL(image) })
                    setErrorMessage({ ...errorMessage, PERSONAL_STORAGEPICTURE: '' })
                    setLoadingImg({ ...loadingImg, ...{ storageImg: false } })
                } else if (type === 'SERIAL') {
                    setImeiImages({
                        ...imeiImages, type: "REFINANCE", images: [{
                            fileName: fileName,
                            fileNameOrigin: fileNameOrigin,
                            mimeType: mimeType,
                            url: url,
                            name: image.name,
                            img: URL.createObjectURL(image)
                        }]
                    })
                    // setPreviewSerial({ name: image.name, img: URL.createObjectURL(image) })
                    setErrorMessage({ ...errorMessage, PERSONAL_SERIALNUMBERPICTURE: '' })
                    setLoadingImg({ ...loadingImg, ...{ serialNumberImg: false } })
                }
            }
        }).catch((e: any) => {
            const errResonseMsg = e?.response?.data?.message ?? e?.message
            
            if (type === 'STORAGE') {
                setLoadingImg({ ...loadingImg, ...{ storageImg: false } })
                setErrorMessage({ ...errorMessage, PERSONAL_STORAGEPICTURE: 'อัพโหลดไฟล์ไม่สำเร็จ' })
                setPhoneStorage({})
                amplitudeTrack('2_Profile_I_failedUpStorage', errResonseMsg)
            } else {
                setLoadingImg({ ...loadingImg, ...{ serialNumberImg: false } })
                setErrorMessage({ ...errorMessage, PERSONAL_SERIALNUMBERPICTURE: 'อัพโหลดไฟล์ไม่สำเร็จ' })
                setImeiImages({})
                amplitudeTrack('2_Profile_I_failedUpSerial', errResonseMsg)
            }
        })
    }

    const onDelete = async (val: any, index: number, type: string) => {
        if (type === 'STORAGE') {
            const img = iphoneStorage?.images.filter((data: any) => data.fileName !== val.fileName)
            setPhoneStorage({ type: "REFINANCE", images: img })
        } else if (type === 'SERIAL') {
            const img = imeiImages?.images.filter((data: any) => data.fileName !== val.fileName)
            setImeiImages({ type: "REFINANCE", images: img })
        }
    }

    const toggleClick = (key: string) => {
        if (key === openTooltip) {
            setOpenTooltipValue('')
        } else {
            setOpenTooltipValue(key);
        }

    };

    return (
        <Styles.Container>
            <Styles.MarginRow>
                <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2'>
                    <AutocompleteSelect
                        options={optionsPrefix || []}
                        value={optionsPrefix
                            ? optionsPrefix?.find(
                                (val: any) => val?.value === prefix
                            )
                            : null}
                        placeholder={'เลือกคำนำหน้าชื่อ'}
                        getOptionLabel={(option: any) => option.label}
                        labelId="contracttype"
                        onChange={(event, value, reason) => {
                            if (value) {
                                setErrorMessage({ ...errorMessage, PERSONAL_PREFIX: '' })
                                setErrorMessage({ ...errorMessage, PERSONAL_OTHERPREFIX: '' })
                                if (value.value !== 'อื่นๆ') {
                                    setPrefixOther('')
                                }
                                setPrefix(value.value)
                                // amplitudeTrack('2_Profile_P_prefix')
                            } else if (reason === 'clear') (
                                setPrefix('')
                            )
                        }}
                        required
                        helperText={errorMessage.PERSONAL_PREFIX}
                        heading='คำนำหน้าชื่อ'
                        key={'prefix-1'}
                    />
                </Col>
                {prefix === 'อื่นๆ' && (
                    <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2'>
                        <InputTextField
                            value={prefixOther || ''}
                            onchange={(event: any) => {
                                setErrorMessage({ ...errorMessage, PERSONAL_OTHERPREFIX: '' })
                                setPrefixOther(event.target.value)
                            }}
                            helperText={errorMessage.PERSONAL_OTHERPREFIX}
                            heading='โปรดระบุ'
                            placeholder='กรอกคคำนำหน้าชื่อ'
                            required
                        // onBlur={() => {
                        //     if (idCard !== '')
                        //         amplitudeTrack('2_Profile_P_prefix')
                        // }}
                        />
                    </Col>
                )}
                <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2'>
                    <InputTextField
                        placeholder={'กรอกชื่อ'}
                        value={firstname || ''}
                        onchange={(event: any) => {
                            setErrorMessage({ ...errorMessage, PERSONAL_NAME: '' })
                            if (checkThaiLang(event.target.value)) {
                                setFirstName(event.target.value)
                            } else if (event.target.value === '') {
                                setFirstName(event.target.value)
                            }
                        }}
                        helperText={errorMessage.PERSONAL_NAME}
                        heading='ชื่อ (ภาษาไทย)'
                        required
                    // onBlur={() => {
                    //     if (firstname !== '') {
                    //         amplitudeTrack('2_Profile_I_firstname')
                    //     }
                    // }}
                    />
                </Col>
                <Col xs={12} md={prefix === 'อื่นๆ' ? 3 : 4} className='pt-2'>
                    <InputTextField
                        placeholder={'กรอกนามสกุล'}
                        value={lastname || ''}
                        onchange={(event: any) => {
                            setErrorMessage({ ...errorMessage, PERSONAL_LASTNAME: '' })
                            if (checkThaiLang(event.target.value)) {
                                setLastname(event.target.value)
                            } else if (event.target.value === '') {
                                setLastname(event.target.value)
                            }
                        }}
                        helperText={errorMessage.PERSONAL_LASTNAME}
                        heading='นามสกุล (ภาษาไทย)'
                        required
                    // onBlur={() => {
                    //     if (lastname !== '') {
                    //         amplitudeTrack('2_Profile_I_lastname')
                    //     }
                    // }}
                    />
                </Col>
            </Styles.MarginRow>
            <Styles.MarginRow>
                <Col xs={12} md={4} className='pt-2'>
                    <InputTextField
                        placeholder={'กรอกเลขบัตรประชาชน'}
                        value={idCard || ''}
                        onchange={(event: any) => {
                            if (event.target.value.length <= 13) {
                                setIdCard(event.target.value)
                                setErrorMessage({ ...errorMessage, PERSONAL_IDCARD: '' })
                            }
                        }}
                        required
                        type='number'
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        heading='เลขบัตรประชาชน'
                        helperText={errorMessage.PERSONAL_IDCARD}
                    // onBlur={() => {
                    //     if (idCard !== '') {
                    //         amplitudeTrack('2_Profile_P_idcard')
                    //     }
                    // }}
                    />
                </Col>
                <Col xs={12} md={4} className='pt-2'>
                    <InputTextField
                        placeholder={'ตัวอย่าง 05/07/2537'}
                        value={birthday || ''}
                        onchange={(event: any) => {
                            handleChangeDate(event)
                        }}
                        required
                        // type='date'
                        heading='วัน/เดือน/ปีเกิด (พ.ศ.)'
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        helperText={errorMessage.PERSONAL_DATEOFBIRTH}
                        onBlur={() => {
                            if (birthday.length !== 0) {
                                if (birthday.length === 10) {
                                    amplitudeTrack('2_Profile_P_age', age)
                                }
                            }
                        }}
                    />
                </Col>
                <Col xs={12} md={4} className='pt-2'>
                    <InputTextField
                        value={age}
                        disabled
                        heading='อายุ'
                    />
                </Col >
            </Styles.MarginRow>
            <Styles.MarginRow>
                <Col xs={12} md={4} className='pt-2'>
                    <InputTextField
                        placeholder='กรอกเบอร์โทรศัพท์'
                        value={phoneNumber || ''}
                        onchange={(event: any) => {
                            if (event.target.value.length <= 10) {
                                setPhoneNumber(event.target.value)
                                setErrorMessage({ ...errorMessage, PERSONAL_PHONENUMBER: '' })
                            }
                        }}
                        type='number'
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        required
                        heading='เบอร์โทรศัพท์'
                        helperText={errorMessage.PERSONAL_PHONENUMBER}
                    // onBlur={() => {
                    //     if (phoneNumber !== '')
                    //         amplitudeTrack('2_Profile_I_tel', phoneNumber)
                    // }}
                    />
                </Col>
                <Col xs={12} md={8} className='pt-2'>
                    <InputTextField
                        placeholder='กรอกที่อยู่อาศัยปัจจุบัน'
                        value={address || ''}
                        onchange={(event: any) => {
                            setAddress(event.target.value)
                            setErrorMessage({ ...errorMessage, PERSONAL_ADDRESS: '' })
                        }}
                        required
                        heading='ที่อยู่อาศัยปัจจุบัน'
                        helperText={errorMessage.PERSONAL_ADDRESS}
                    // onBlur={() => {
                    //     if (address !== '')
                    //         amplitudeTrack('2_Profile_P_address')
                    // }}
                    />
                </Col>
            </Styles.MarginRow>
            <Styles.MarginRow>
                <Col xs={12} md={3} className='pt-2'>
                    <InputTextField
                        placeholder='กรอกรหัสไปรษณีย์'
                        value={zipCode || ''}
                        onchange={(event: any) => {
                            if (event.target.value.length <= 5) {
                                setZipCode(event.target.value)
                                setCheckZipcode(false)
                                setErrorMessage({ ...errorMessage, PERSONAL_ZIPCODE: '' })
                            }
                        }}
                        required
                        type='number'
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        heading='รหัสไปรษณีย์'
                        helperText={errorMessage.PERSONAL_ZIPCODE}
                    />
                </Col>
                <Col xs={12} md={3} className='pt-2'>
                    <AutocompleteSelect
                        key={'province'}
                        options={optionProvice || []}
                        value={_.find(optionProvice, (val: any) => val.value === province) || null}
                        placeholder={'เลือกจังหวัด'}
                        getOptionLabel={(option: any) => option.label}
                        labelId="province"
                        onChange={(event, value, reason) => {
                            if (value) {
                                setErrorMessage({ ...errorMessage, PERSONAL_PROVINCE: '' })
                                // amplitudeTrack('2_Profile_P_province')
                                setProvince(value.value)
                            } else if (reason === 'clear') (
                                setProvince('')
                            )
                        }}
                        required
                        heading='จังหวัด'

                        helperText={errorMessage.PERSONAL_PROVINCE}
                    />
                </Col>
                <Col xs={12} md={3} className='pt-2'>
                    <AutocompleteSelect
                        key={'district'}
                        options={optionDistrict || []}
                        value={_.find(optionDistrict, (val: any) => val.value === district) || null}
                        placeholder={'เลือกเขต/อำเภอ'}
                        getOptionLabel={(option: any) => option.label}
                        labelId="district"
                        onChange={(event, value, reason) => {
                            if (value) {
                                setErrorMessage({ ...errorMessage, PERSONAL_DISTRICT: '' })
                                // amplitudeTrack('2_Profile_P_district')
                                setDistrict(value.value)
                            } else if (reason === 'clear') (
                                setDistrict('')
                            )
                        }}
                        required
                        heading='เขต/อำเภอ'

                        helperText={errorMessage.PERSONAL_DISTRICT}
                    />
                </Col>
                <Col xs={12} md={3} className='pt-2'>
                    <AutocompleteSelect
                        key={'subdistrict'}
                        options={optionSubDistrict || []}
                        value={_.find(optionSubDistrict, (val: any) => val.value === subdistrict) || null}
                        placeholder={'เลือกแขวง/ตำบล'}
                        getOptionLabel={(option: any) => option.label}
                        labelId="subdistrict"
                        onChange={(event, value, reason) => {
                            if (value) {
                                setErrorMessage({ ...errorMessage, PERSONAL_SUBDISTRICT: '' })
                                // amplitudeTrack('2_Profile_P_subdistrict')
                                setSubdistrict(value.value)
                            } else if (reason === 'clear') (
                                setSubdistrict('')
                            )
                        }}
                        required
                        heading='แขวง/ตำบล'
                        helperText={errorMessage.PERSONAL_SUBDISTRICT}
                    />
                </Col>
            </Styles.MarginRow>
            <Styles.MarginRow>
                <Col xs={12} md={6} className='pt-2'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <span>Facebook</span><span style={{ color: colors.danger }}>*(หากไม่ถูกต้อง จะไม่ได้รับการพิจารณา)</span>
                        </div>
                        <div onClick={() => {
                            setIsShowMoalFacebook(true)
                            if (!checkIsShowModalFacebook) {
                                amplitudeTrack('2_Profile_P_fbExample')
                            }
                        }}>
                            <span style={{ color: colors.blue, cursor: 'pointer', textDecoration: 'underline' }}>ตัวอย่าง</span>
                        </div>
                    </div>
                    <InputTextField
                        placeholder='กรอก Facebook'
                        value={facebook || ''}
                        onchange={(event: any) => {
                            setFacebook(event.target.value)
                            setErrorMessage({ ...errorMessage, PERSONAL_FACEBOOK: '' })
                        }}
                        required
                        helperText={errorMessage.PERSONAL_FACEBOOK}
                        onBlur={() => {
                            if (facebook && !checkFacebook) {
                                setCheckFacebook(true)
                                amplitudeTrack('2_Profile_P_fb')
                            }
                        }}
                    />
                </Col>
                <Col xs={12} md={6} className='pt-2'>
                    <AutocompleteSelect
                        key={'phoneSystem'}
                        options={optionsPhoneSystem || []}
                        value={_.find(optionsPhoneSystem, (val: any) => val.value === phoneSystem) || null}
                        placeholder={'เลือกระบบโทรศัพท์มือถือ'}
                        getOptionLabel={(option: any) => option.label}
                        labelId="phoneSystem"
                        onChange={(event, value, reason) => {
                            if (value) {
                                setErrorMessage({ ...errorMessage, PERSONAL_PHONESYSTEM: '' })
                                // amplitudeTrack('2_Profile_P_phonesystem')
                                setPhoneSystem(value.value)
                            } else if (reason === 'clear') (
                                setPhoneSystem('')
                            )
                        }}
                        required
                        heading='ท่านใช้ระบบโทรศัพท์มือถือแบบใด'
                        helperText={errorMessage.PERSONAL_PHONESYSTEM}
                    />
                </Col>
            </Styles.MarginRow>

            {props.data?.type === 'REFINANCE' && (
                <>
                    <Styles.MarginRow>
                        <Col xs={12} md={6} className='pt-2'>
                            <InputTextField
                                placeholder='กรอกหมายเลขประจำเครื่อง'
                                value={serialNumber || ''}
                                onchange={(event: any) => {
                                    setSerialNumber(event.target.value)
                                    setErrorMessage({ ...errorMessage, PERSONAL_SERIALNUMBER: '' })
                                }}
                                required
                                heading='เลขประจำเครื่อง (Serial)'
                                helperText={errorMessage.PERSONAL_SERIALNUMBER}
                            />
                        </Col>
                    </Styles.MarginRow>
                    <Styles.MarginRow>
                        <Col xs={12} md={6} className='pt-2'>
                            <div className='pb-1'>
                                <span>รูปเลขประจำเครื่อง (Serial)</span>
                                <span style={{ color: colors.danger }}>*</span>
                                <span style={{ paddingLeft: 8 }}>
                                    <Tooltip title={
                                        <div className='font-14'>
                                            {'เข้าไปที่ การตั้งค่า > ทั่วไป > เกี่ยวกับ > แคปหน้านี้ให้เห็นเลขประจำเครื่อง (Serial)'}
                                        </div>
                                    }
                                        placement='top'
                                        arrow
                                        open={openTooltip === 'storage'}
                                    >
                                        <IconButton onClick={() => toggleClick('storage')}>
                                            <ErrorOutlineIcon style={{ fill: colors.sky, cursor: 'pointer' }} />
                                        </IconButton>
                                    </Tooltip>

                                </span>
                            </div>
                            <UploadImage
                                onUpload={(event: any) => { onUpload(event, 'SERIAL') }}
                                helperText={errorMessage.PERSONAL_SERIALNUMBERPICTURE}
                                loading={loadingImg.serialNumberImg}

                            />
                        </Col>
                        {imeiImages?.images && (
                            <Col xs={12} md={6} style={{ paddingTop: width <= 575 ? 16 : 35 }}>
                                {imeiImages?.images?.map((val: any, index: number) => {
                                    return (
                                        <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'SERIAL') }} />
                                    )
                                })}
                            </Col>
                        )}
                    </Styles.MarginRow>
                    <Styles.MarginRow>
                        <Col xs={12} md={6} className='pt-2'>
                            <div className='pb-1'>
                                <span>รูป {props.data?.productGroup || ''} Storage</span>
                                <span style={{ color: colors.danger }}>*</span>
                                <Tooltip
                                    title={<div className='font-14'>{'เข้าไปที่ การตั้งค่า > ทั่วไป > พื้นที่จัดเก็บข้อมูลของ iPhone (iPhone Storage) > แคปหน้านี้'}</div>}
                                    placement="top"
                                    arrow
                                    open={openTooltip === 'imei'}
                                >
                                    <IconButton
                                        style={{ paddingLeft: 8 }}
                                        onClick={() => toggleClick('imei')}
                                    ><ErrorOutlineIcon style={{ fill: colors.sky, cursor: 'pointer' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <UploadImage
                                onUpload={(event: any) => { onUpload(event, 'STORAGE') }}
                                helperText={errorMessage.PERSONAL_STORAGEPICTURE}
                                loading={loadingImg.storageImg}

                            />
                        </Col>
                        {iphoneStorage?.images && (
                            <Col xs={12} md={6} className='pt-2' style={{ paddingTop: width <= 575 ? 16 : 35 }}>
                                {iphoneStorage?.images?.map((val: any, index: number) => {
                                    return (
                                        <UploadImageList preview={val} onDelete={() => { onDelete(val, index, 'STORAGE') }} />
                                    )
                                })}
                            </Col>
                        )}
                    </Styles.MarginRow>
                </>
            )
            }

            <Styles.MarginRow className='pt-2'>
                <Col>
                    <ButtonCustom
                        variant={'outline'}
                        btnStyle={{ width: '100%' }}
                        textButton={'ย้อนกลับ'}
                        disabled={disableButtonNext}
                        onClick={() => { onClickBack() }}
                    />
                </Col>
                <Col>
                    <ButtonCustom
                        btnStyle={{ width: '100%' }}
                        textButton={'ยืนยัน'}
                        disabled={disableButtonNext}
                        onClick={() => { onSubmit() }}
                    />
                </Col>
            </Styles.MarginRow>

            <ModalCustom
                size="lg"
                title={''}
                modalTheme="default"
                onSubmit={() => { setIsShowMoalFacebook(false) }}
                onClose={() => { setIsShowMoalFacebook(false) }}
                textBtnConfirm={'ยืนยัน'}
                isShowProps={isShowModalFacebook}
                closeButton
                component={
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <img height="auto" width="40%" src={imgFacebook} />
                        </div>
                    </>
                }
            />

        </Styles.Container >
    );
}
