import { Col, Row } from "react-bootstrap";
import {
  imagesInstallment,
  imagesRefinace,
  imgs,
} from "../../../constants/images";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { colors } from "../../../constants/colors";
import Branch from "../componant/branch/branch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import iconWeb1 from "../../../assets/icon/landingpage/installment/web/iconเงินก้อน.svg";
import iconWeb2 from "../../../assets/icon/landingpage/installment/web/iconบัตรเครดิต.svg";
import iconWeb3 from "../../../assets/icon/landingpage/installment/web/iconผ่อนได้.svg";
import iconWeb4 from "../../../assets/icon/landingpage/installment/web/iconสมัครง่าย.svg";
import iconWeb5 from "../../../assets/icon/landingpage/installment/web/iconอนุมัติไว.svg";
import iconWeb6 from "../../../assets/icon/landingpage/installment/web/iconยอดผ่อนต่ำ.svg";
import iconWeb7 from "../../../assets/icon/landingpage/installment/web/iconผ่อนสบาย.svg";
import iconWeb8 from "../../../assets/icon/landingpage/installment/web/iconเครื่องใหม่มือหนึ่ง.svg";
import iconWeb9 from "../../../assets/icon/landingpage/installment/web/iconมีสาขาให้บริการ.svg";

import iconMobile1 from "../../../assets/icon/landingpage/installment/mobile/iconเงินก้อน.svg";
import iconMobile2 from "../../../assets/icon/landingpage/installment/mobile/iconบัตรเครดิต.svg";
import iconMobile3 from "../../../assets/icon/landingpage/installment/mobile/iconผ่อนได้.svg";
import iconMobile4 from "../../../assets/icon/landingpage/installment/mobile/iconสมัครง่าย.svg";
import iconMobile5 from "../../../assets/icon/landingpage/installment/mobile/iconอนุมัติไว.svg";
import iconMobile6 from "../../../assets/icon/landingpage/installment/mobile/iconยอดผ่อนต่ำ.svg";
import iconMobile7 from "../../../assets/icon/landingpage/installment/mobile/iconผ่อนสบาย.svg";
import iconMobile8 from "../../../assets/icon/landingpage/installment/mobile/iconเครื่องใหม่.svg";
import iconMobile9 from "../../../assets/icon/landingpage/installment/mobile/iconมีสาขาให้บริการ.svg";
import Header2 from "../../../components/header/header2";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Footer2 from "../../../components/footer/footer2";
import { motion } from "framer-motion";
import Review from "../componant/review/review";
import * as Style from "../style";
import zIndex from "@mui/material/styles/zIndex";
import LarkService from "../../../utils/lark/lark";

interface WindowWithAdTech extends Window {
  adTechTrackerInit?: (key: string) => void;
  tmfire?: (publicKey: string, eventData: object) => void;
}

export default function Installment() {
  const history = useHistory();

  const theme = useTheme();
  // เช็คว่า breakpoint 'md' ขึ้นไปไหม (>= md)
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const customWindow = window as WindowWithAdTech;
  const publicKey = process.env.REACT_APP_API_ADTECH_KEY || "";
  console.log("publicKey", publicKey);
  const [seconds, setSeconds] = useState(0);
  const triggeredRef = useRef(new Set());

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_API_ADTECH || "";
    script.async = true;
    const customWindow = window as WindowWithAdTech;
    // script.onload = () => {
    //   if (customWindow.adTechTrackerInit) {
    //     customWindow.adTechTrackerInit(
    //       process.env.REACT_APP_API_ADTECH_KEY || ""
    //     );
    //   } else {
    //     new LarkService(
    //       "adTechTrackerInit sp_leasing1 function is not available",
    //       "red"
    //     ).send(`error : ${customWindow}`);
    //     console.error("adTechTrackerInit function is not available");
    //   }
    // };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newSeconds = prevSeconds + 1;
        if ([3, 15, 30, 60].includes(newSeconds)) {
          const eventData = {
            data: {
              event_name: `View ${newSeconds} sec`,
              custom_data: {
                currency: "thb",
                value: 10,
              },
            },
          };
          const messageLark = `data:  event_name: View ${newSeconds} sec`;

          if (customWindow.tmfire) {
            customWindow.tmfire(publicKey, eventData);
            // new LarkService('adTechTracker sp_leasing1 send event', 'green').send(
            //     `send : ${messageLark}`
            // );
          }
        }
        return newSeconds;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const totalScroll = docHeight - windowHeight;
      if (totalScroll <= 0) return;

      const scrollPercent = (scrollTop / totalScroll) * 100;
      if (scrollPercent >= 25 && !triggeredRef.current.has(25)) {
        sendEvent(25);
        console.log("Scroll 25%");
        triggeredRef.current.add(25);
      }
      if (scrollPercent >= 50 && !triggeredRef.current.has(50)) {
        sendEvent(50);
        console.log("Scroll 50%");
        triggeredRef.current.add(50);
      }
      if (scrollPercent >= 75 && !triggeredRef.current.has(75)) {
        sendEvent(75);
        console.log("Scroll 75%");
        triggeredRef.current.add(75);
      }
      if (scrollPercent >= 95 && !triggeredRef.current.has(95)) {
        sendEvent(95);
        console.log("Scroll 95%");
        triggeredRef.current.add(95);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sendEvent = (value: number) => {
    const eventData = {
      data: {
        event_name: `Scroll ${value} %`,
        custom_data: {
          currency: "thb",
          value: 10,
        },
      },
    };
    const messageLark = `data:  event_name: Scroll ${value}`;

    if (customWindow.tmfire) {
      customWindow.tmfire(publicKey, eventData);
      // new LarkService('adTechTracker sp_leasing1 send event', 'green').send(
      //     `send : ${messageLark}`
      // );
    }
  };

  // bullet “เลือกผ่อนกับ Ufriend”
  const benefitsUfriend = [
    "สมัครง่าย ใช้เพียงบัตรประชาชนใบเดียว",
    "ไม่ต้องใช้บัตรเครดิต ติดบูโรก็ผ่อนได้",
    "ไม่ต้องเตรียมเอกสารที่ยุ่งยาก",
    "อนุมัติ ภายใน 10 นาที รับผลพร้อมรับเครื่องทันที",
    "มีสาขาให้บริการทั่วประเทศ สะดวก เชื่อถือได้",
    "ชำระผ่านแอปพลิเคชัน จัดการทุกอย่างได้ในที่เดียว",
  ];

  // bullet “เลือกผ่อนที่อื่น”
  const disadvantagesOthers = [
    "ส่วนใหญ่ต้องใช้บัตรเครดิต",
    "มีการตรวจสอบเครดิตบูโรอย่างเข้มงวด",
    "ใช้เอกสารเยอะ มีขั้นตอนการดำเนินการที่ยุ่งยาก",
    "บางที่อาจใช้เวลาหลายวันกว่าจะอนุมัติผ่าน",
    "บางที่ให้บริการเฉพาะออนไลน์ ขาดความเชื่อมั่น",
    "ผ่อนระยะยาว ไม่มีแอปพลิเคชันรับรอง",
  ];

  // คุณสมบัติ
  const qualifications = [
    "นักศึกษา\nอายุ 18 ปีขึ้นไป",
    "บุคคลธรรมดา\nอายุ 20 - 60 ปี",
    "มีบัตรประชาชน\nสัญชาติไทย",
  ];

  const navigateToRefinance = () => {
    const eventData = {
      data: {
        event_name: "Register_คลิก",
        custom_data: {
          currency: "thb",
          value: 10,
        },
      },
    };
    if (customWindow.tmfire) {
      customWindow.tmfire(publicKey, eventData);
      // const messageLark = `data:  event_name: ${eventData.data.event_name}`;
      // new LarkService('adTechTrackerInit sp_leasing1 send event', 'green').send(
      //     `send : ${messageLark}`
      // );
    } else {
      new LarkService(
        "adTechTrackerInit sp_leasing1 send event error",
        "red"
      ).send(`error : ${customWindow}`);
    }
    history.push(`/register/?type=INSTALLMENT`);
  };

  const navigateToLine = () => {
    window.location.href = "https://lin.ee/7s0c9CX";
  };

  const sectionRef = useRef<HTMLDivElement>(null);
  const sectionRef2 = useRef<HTMLDivElement>(null);
  const sectionRef3 = useRef<HTMLDivElement>(null);
  const sectionRef4 = useRef<HTMLDivElement>(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      console.log(sectionRef.current);
      sectionRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };

  const scrollToSection2 = () => {
    if (sectionRef2.current) {
      sectionRef2.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };
  const scrollToSection3 = () => {
    if (sectionRef3.current) {
      sectionRef3.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };
  const scrollToSection4 = () => {
    if (sectionRef4.current) {
      sectionRef4.current.scrollIntoView({ behavior: "smooth" }); // Scroll Smooth
    }
  };

  return (
    <>
      <Header2
        scrollToSection={scrollToSection}
        scrollToSection2={scrollToSection2}
        scrollToSection3={scrollToSection3}
        scrollToSection4={scrollToSection4}
      />

      <div className="pt-5" ref={sectionRef}>
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            src={isMdUp ? imagesInstallment.UFW1_1 : imagesInstallment.MUF1_1}
            width="100%"
            alt="Banner 1"
          />
          <Box
            sx={{
              position: "absolute",
              bottom: { lg: "40%", md: "36%", xs: "11%" },
              left: { lg: "73%", md: "74%", xs: "22%" },
              // transform: "translateX(-50%)",
            }}
          >
            <motion.div
              animate={{ scale: [1, 1.1, 1] }} // Pulsing effect
              transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFCC00",
                  color: "#174ea6",
                  fontWeight: "bold",
                  px: 3,
                  py: 1,
                  borderRadius: "25px",
                  "&:hover": {
                    backgroundColor: "#FFD700",
                  },
                }}
                onClick={() => navigateToRefinance()}
              >
                <Typography
                  style={{ fontWeight: 700 }}
                  sx={{ typography: { xl: "h5", sm: "subtitle2", xs: "h6" } }}
                >
                  สนใจผ่อนไอโฟน คลิก
                </Typography>
              </Button>
            </motion.div>
          </Box>
        </Box>
        {!isMdUp && (
          <img src={imagesInstallment.MUF1_2} width="100%" alt="Banner 2" />
        )}

        <Container>
          <Grid container spacing={2} alignItems="center" pb={4} px={2}>
            <Grid item xs={12} md={12} lg={8} className="pt-5">
              <Typography
                style={{ fontWeight: 500 }}
                sx={{
                  color: colors.themeMainColor,
                  mb: 2,
                  typography: { lg: "h2", xs: "h4" },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                เพราะของมันต้องมี!
              </Typography>
              <Typography
                style={{ fontWeight: 300 }}
                sx={{
                  typography: { lg: "h6", md: "h6", xs: "body2" },
                  lineHeight: { xs: 1.6 },
                }}
              >
                ปฏิเสธไม่ได้เลยว่าในยุคนี้ โทรศัพท์มือถือหรือสมาร์ทโฟนนั้น
                <Box
                  component="br"
                  sx={{ display: { xs: "block", sm: "none" } }}
                />
                ได้กลายเป็นปัจจัยที่ 5
                <Box
                  component="br"
                  sx={{ display: { xs: "none", xl: "block" } }}
                />
                ของชีวิต เพราะไม่ใช่เพียงแค่การติดต่อ
                <Box
                  component="br"
                  sx={{ display: { xs: "block", sm: "none" } }}
                />
                สื่อสารเท่านั้น แต่สมาร์ทโฟนยังกลายเป็น
                <Box
                  component="br"
                  sx={{ display: { xs: "none", xl: "block" } }}
                />
                ศูนย์กลางของชีวิต
                <Box
                  component="br"
                  sx={{ display: { xs: "block", sm: "none" } }}
                />
                ประจำวัน ทั้งด้านของการทำงาน ช้อปปิ้งออนไลน์ การเรียนรู้
                <Box
                  component="br"
                  sx={{ display: { xs: "none", xl: "block" } }}
                />
                สิ่งใหม่ หรือแม้กระทั่งสร้างความบันเทิง การมีสมาร์ทโฟนดี ๆ
                <Box
                  component="br"
                  sx={{ display: { xs: "block", sm: "none" } }}
                />
                สักเครื่องจึงไม่ใช่
                <Box
                  component="br"
                  sx={{ display: { xs: "none", xl: "block" } }}
                />
                เรื่องของความหรูหราอีกต่อไป
              </Typography>

              <Box
                sx={{
                  mt: 3,
                  backgroundColor: colors.themeMainColor,
                  p: 2,
                  borderRadius: 4,
                  maxWidth: "max-content",
                }}
              >
                <Typography
                  style={{ fontWeight: 300 }}
                  sx={{
                    typography: { lg: "h6", md: "h6", xs: "body2" },
                    lineHeight: 1.6,
                    color: colors.white,
                    borderRadius: 4,
                  }}
                >
                  แต่เป็นสิ่งจำเป็นที่ช่วยยกระดับคุณภาพชีวิต และเปิดโอกาสใหม่ ๆ
                  ได้อย่าง
                  <Box
                    component="br"
                    sx={{ display: { xs: "none", xl: "block" } }}
                  />
                  ไม่มีที่สิ้นสุดและถ้าจะหารักโฟนดี ๆ
                  สักเครื่องที่ตอบโจทย์ในเรื่องของ
                  <Box
                    component="br"
                    sx={{ display: { xs: "none", xl: "block" } }}
                  />
                  ความคุ้มค่า ความทันสมัย และการใช้งาน
                  <strong style={{ color: colors.yellow }}> “iPhone” </strong>
                  ก็คือตัวเลือกอันดับต้น ๆ
                  <Box
                    component="br"
                    sx={{ display: { xs: "none", xl: "block" } }}
                  />
                  ที่ได้รับความนิยมมากที่สุดในโลก
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", lg: "flex-end" },
              }}
            >
              <Box
                component="img"
                src={
                  isMdUp ? imagesInstallment.UFW1_2 : imagesInstallment.MUF1_3
                } // ใส่ URL หรือ path ของรูปที่ต้องการ
                alt="iPhone Lady"
                sx={{
                  width: { xs: "100%", md: "60%", lg: "100%" },
                  borderRadius: 2,
                }}
              />
            </Grid>
          </Grid>
        </Container>

        <img
          src={isMdUp ? imagesInstallment.UFW1_3 : imagesInstallment.MUF1_4}
          width="100%"
          alt="Banner 3"
        />

        <Box sx={{ px: { xs: 2, md: 4 }, py: 4 }} ref={sectionRef2}>
          {/* Section 1 */}
          <Grid
            container
            spacing={1}
            sx={{
              gap: { xs: 1, md: 6 },
              justifyContent: "center",
            }}
            alignItems={"center"}
          >
            {/* Left Image */}
            <Grid item xs={12} md={6} lg={5}>
              <Box
                component="img"
                src={imagesInstallment.UFW1_4}
                alt="Ufriend Banner"
                sx={{ width: "95%", borderRadius: 2 }}
              />
            </Grid>

            {/* Right Content */}
            <Grid xs={12} md={5} lg={4}>
              <Typography
                variant="h5"
                sx={{
                  mb: 2,
                  lineHeight: 1.8,
                  ml: { md: 0, xs: 3 },
                  typography: { lg: "h5", md: "body2", xs: "body2" },
                }}
              >
                เราเล็งเห็นความสำคัญและเข้าใจปัญหาของผู้บริโภคเป็นอย่างดี
                <Box
                  component="br"
                  sx={{ display: { xs: "none", xl: "block" } }}
                />
                เพราะการที่ซื้อไอโฟนดี ๆ
                สักเครื่องด้วยเงินสดนั้นไม่ใช้เรื่องง่าย
                <Box
                  component="br"
                  sx={{ display: { xs: "none", xl: "block" } }}
                />
                เราจึงก่อตั้ง <strong>Ufriend</strong> ขึ้นมา
                เพื่อให้บริการรับผ่อนไอโฟน
              </Typography>
              <Grid container>
                {[
                  isMdUp ? iconWeb1 : iconMobile1,
                  isMdUp ? iconWeb2 : iconMobile2,
                  isMdUp ? iconWeb3 : iconMobile3,
                ].map((item, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={item}
                        alt={`icon-${index}`}
                        sx={{
                          width: { lg: "100%", md: "80%", xs: "65%" },
                          mb: { xs: 0, md: 1 },
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Typography
                variant="h5"
                sx={{
                  mb: { sx: 1, md: 2 },
                  pt: { sx: 1, md: 0 },
                  typography: { lg: "h5", md: "body2", xs: "body2" },
                }}
                className="pt-2 text-center"
              >
                ไม่ว่าคุณจะประกอบอาชีพใดก็สามารถมีไอโฟนในฝัน
                <br />
                เป็นของตัวเองได้อย่างไร้กังวล
              </Typography>
            </Grid>
          </Grid>

          {/* Section 2 */}
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography
              fontWeight="bold"
              color={colors.themeMainColor}
              sx={{ typography: { lg: "h4", md: "h6", xs: "body1" } }}
            >
              ผ่อนไอโฟนกับ Ufriend
            </Typography>
            <Typography
              sx={{ mb: 4, typography: { lg: "h5", md: "body1", xs: "body2" } }}
            >
              อนุมัติไว นัดรับเครื่องใหม่ได้ทันที
            </Typography>

            {/* Features */}
            <Grid container>
              {[
                isMdUp ? iconWeb4 : iconMobile4,
                isMdUp ? iconWeb5 : iconMobile5,
                isMdUp ? iconWeb6 : iconMobile6,
                isMdUp ? iconWeb7 : iconMobile7,
                isMdUp ? iconWeb8 : iconMobile8,
                isMdUp ? iconWeb9 : iconMobile9,
              ].map((item, index) => (
                <Grid xs={12} sm={6} md={4} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      component="img"
                      src={item}
                      alt={`feature-${index}`}
                      sx={{ width: { lg: "50%", md: "80%", xs: "65%" } }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#002c5f",
            color: "#fff",
            padding: "24px 24px",
          }}
          onClick={() => navigateToRefinance()}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
            }}
          >
            สนใจผ่อนไอโฟน <span style={{ color: colors.yellow }}>คลิก!</span>
          </Typography>
        </Box>

        <img
          src={isMdUp ? imagesInstallment.UFW1_5 : imagesInstallment.MUF1_6}
          width="100%"
          alt="Banner 5"
        />

        <Style.Container
          style={{ paddingBottom: "0px" }}
          sx={{ padding: { lg: "0px 148px", md: "0px 80px" } }}
        >
          <Grid
            container
            sx={{
              mt: 6,
              // justifyContent: "center",
              // textAlign: { xs: "center", md: "left" }
            }}
          >
            <Grid
              xs={12}
              md={12}
              lg={4}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  typography: { lg: "h4", xl: "h5", md: "h5", xs: "h5" },
                }}
                className="text-center"
              >
                ทำไมลูกค้าถึงไว้วางใจ?
              </Typography>
              <Typography
                style={{ fontWeight: 500 }}
                color={colors.themeMainColor}
                mt={1}
                sx={{
                  typography: { xl: "h4", lg: "h5", md: "h5", xs: "h5" },
                }}
              >
                เลือกผ่อนกับทาง
              </Typography>
              <Box
                component="img"
                src={imagesRefinace.UFWEB2_11}
                alt=""
                sx={{
                  width: { xs: "70%", sm: "50%", md: "60%", lg: "85%" },
                  pt: 3,
                }}
              />
            </Grid>

            <Grid
              xs={12}
              md={12}
              sm="auto"
              lg={4}
              px={2}
              display="flex"
              justifyContent={{ xs: "center", md: "center" }}
              alignItems="center"
              pt={{ lg: 0, xs: 3 }}
            >
              <Card
                sx={{
                  borderRadius: 2,
                  border: `1px solid ${colors.themeMainColor}`,
                  minWidth: { lg: "100%", md: "100%", xs: "100%" },
                  maxWidth: { xl: "100%", md: "100%", xs: "100%" },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.themeMainColor,
                    color: "#fff",
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold">
                    เลือกผ่อนกับ Ufriend
                  </Typography>
                </Box>
                <CardContent>
                  <List>
                    {benefitsUfriend.map((item, idx) => (
                      <ListItem key={idx} disablePadding sx={{ py: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <CheckCircleIcon sx={{ color: colors.yellow }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: {
                              xl: "18px",
                              lg: "18px",
                              md: "18px",
                              xs: "16px",
                            },
                          }}
                          primary={item}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={12}
              sm="auto"
              lg={4}
              px={2}
              display="flex"
              justifyContent={{ xs: "center", md: "center" }}
              alignItems="center"
              pt={{ lg: 0, xs: 3 }}
            >
              <Card
                sx={{
                  borderRadius: 2,
                  border: `1px solid ${colors.darkGray}`,
                  minWidth: { lg: "100%", md: "100%", xs: "100%" },
                  maxWidth: { xl: "100%", md: "100%", xs: "100%" },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.darkGray,
                    color: "#fff",
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold">
                    เลือกผ่อนที่อื่น
                  </Typography>
                </Box>
                <CardContent>
                  <List>
                    {disadvantagesOthers.map((item, idx) => (
                      <ListItem key={idx} disablePadding sx={{ py: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <CancelIcon sx={{ color: colors.darkGray }} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: {
                              xl: "18px",
                              lg: "18px",
                              md: "18px",
                              xs: "16px",
                            },
                          }}
                          primary={item}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              mt: 4,
              alignItems: "center",
            }}
          >
            <Grid
              xs={12}
              md={12}
              xl={4}
              lg={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  color: "#333",
                  typography: { lg: "h4", md: "h6", xs: "h6" },
                  textAlign: { xs: "center", md: "center", lg: "left" },
                }}
              >
                อยากผ่อนไอโฟนกับ{" "}
                <span style={{ color: colors.themeMainColor }}>Ufriend</span>
                <br />
                ต้องมีคุณสมบัติใดบ้าง?
              </Typography>
            </Grid>
            <Grid
              pt={{ md: 3, lg: 0 }}
              xs={12}
              md={12}
              xl={2}
              lg={2}
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                component="img"
                src={imagesInstallment.MUF1_7}
                alt="iPhone"
                sx={{
                  width: { xl: "100%", lg: "94%", md: "63%", xs: "60%" },
                  maxWidth: "300px",
                }}
              />
            </Grid>
            <Grid pt={{ md: 3, lg: 0 }} xs={12} md={12} xl={6} lg={6}>
              <Grid
                container
                sx={{
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    xs: "column",
                  },
                  justifyContent: { xs: "center", md: "center" },
                }}
              >
                {qualifications.map((text, index) => (
                  <>
                    {/* Text Section */}
                    <Grid item>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          whiteSpace: "pre-line", // รองรับการแสดง \n
                          fontWeight: 500,
                          typography: { xl: "h5", md: "body1", xs: "h6" },
                          pb: { xs: index == 2 ? 2 : 0, sm: 0 },
                          pt: { xs: index == 0 ? 2 : 0, sm: 0 },
                        }}
                      >
                        {text}
                      </Typography>
                    </Grid>

                    {/* Divider: ไม่แสดง Divider หลังข้อความสุดท้าย */}
                    {index < qualifications.length - 1 && (
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Divider
                          orientation="vertical"
                          sx={{
                            height: { md: 60, xs: 0 }, // ความสูงของเส้น
                            width: { xs: "50%", md: 0 },
                            mx: 2, // ระยะห่างแนวนอน
                            borderWidth: 1,
                            borderColor: colors.themeMainColor,
                            margin: { md: 0, xs: "20px" },
                          }}
                        />
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Style.Container>

        <img
          src={isMdUp ? imagesInstallment.UFW1_7 : imagesInstallment.MUF1_8}
          width="100%"
          alt="Banner 7"
        />

        <Style.Container>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#fff",
              py: { xs: 4, md: 6 },
            }}
          >
            <Grid container alignItems="center">
              <Grid xs={12} md={6}>
                <Box
                  component="img"
                  src={
                    isMdUp ? imagesInstallment.UFW1_8 : imagesInstallment.MUF1_9
                  }
                  alt="iPhone"
                  sx={{
                    width: "100%",
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
                sx={{
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    typography: { lg: "h4", md: "h5", xs: "h4" },
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  ผ่อนง่าย ปลอดภัย {isMdUp && <>&nbsp;</>}
                  <Box
                    component="br"
                    sx={{ display: { xs: "block", md: "none" } }}
                  />
                  <span
                    style={{
                      color: colors.themeMainColor,
                    }}
                  >
                    เชื่อถือได้ 100%
                  </span>
                </Typography>

                <Typography
                  style={{ fontWeight: 200, lineHeight: 1.6 }}
                  sx={{
                    mt: 2,
                    typography: {
                      xl: "h6",
                      lg: "body1",
                      md: "body2",
                      xs: "body1",
                    },
                    lineHeight: { lg: 2.0, md: 1.8, xs: 1.6 },
                    textAlign: { xs: "left", md: "left" },
                  }}
                >
                  Ufriend เราเป็นหนึ่งในบริการผ่อนไอโฟน ด้วยสาขากว่า 20
                  แห่งทั่วประเทศ
                  <Box
                    component="br"
                    sx={{ display: { xs: "none", lg: "block" } }}
                  />
                  เราพร้อมให้บริการคุณทุกที่
                  ทุกเวลาและยังมีแผนขยายสาขาอย่างต่อเนื่อง
                  <Box
                    component="br"
                    sx={{ display: { xs: "none", lg: "block" } }}
                  />
                  เพื่อรองรับการให้บริการ ที่ทั่วถึง
                  พร้อมระบบชำระเงินผ่านแอปที่ทันสมัย
                  <Box
                    component="br"
                    sx={{ display: { xs: "none", lg: "block" } }}
                  />
                  ปลอดภัย ใช้งานง่าย ตรวจสอบได้แบบ Real-Time
                </Typography>
                <motion.div
                  animate={{ x: [-5, 5, -5, 5, 0] }} // Shake effect
                  transition={{
                    duration: 0.7,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }} // Loops forever
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3,
                      backgroundColor: colors.themeMainColor,
                      color: "#fff",
                      fontWeight: 700,
                      borderRadius: 4,
                      padding: "10px 30px",
                      fontSize: "30px",
                      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                      "&:hover": {
                        backgroundColor: "#0e3979",
                      },
                      textAlign: { xs: "center", md: "left" },
                    }}
                    onClick={() => navigateToRefinance()}
                  >
                    สมัครเลย&nbsp;
                    <Box component="span" sx={{ color: colors.yellow }}>
                      คลิก
                    </Box>
                  </Button>
                </motion.div>
              </Grid>
            </Grid>
          </Box>
        </Style.Container>

        {/* <Branch /> */}

        <div className="text-center">
          <Typography variant="h4" fontWeight="400" ref={sectionRef3}>
            รีวิวบางส่วนจากลูกค้าจริง
          </Typography>
          <Typography variant="h4" fontWeight="400">
            ทีใช้บริการผ่อนไอโฟน{" "}
            <span style={{ color: colors.themeMainColor }}>Ufriend</span>
          </Typography>
          <img
            src={isMdUp ? imagesInstallment.UFW1_9 : imagesInstallment.MUF1_10}
            width="80%"
            alt="Review Section"
          />
        </div>
        <Review type="INSTALLMENT" />

        <Box
          sx={{
            width: "100%",
            py: { xs: 4, md: 6 },
            backgroundColor: "#fff",
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent={{ xs: "center" }}
                sx={{
                  textAlign: "center",
                }}
              >
                <div>
                  <Box sx={{ textAlign: "center", mb: { xs: 3, md: 4 } }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: 600,
                        color: colors.themeMainColor,
                      }}
                    >
                      ข้อเสนอสุดพิเศษ!
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 400,
                        mt: 1,
                      }}
                    >
                      สมัครผ่อนไอโฟนกับ{" "}
                      <Box
                        component="span"
                        sx={{ color: colors.themeMainColor, fontWeight: 600 }}
                      >
                        Ufriend
                      </Box>{" "}
                      วันนี้
                    </Typography>
                  </Box>
                  <Box
                    component="img"
                    src={
                      isMdUp
                        ? imagesInstallment.UFW1_11
                        : imagesInstallment.MUF1_11
                    } // แทนที่ด้วยรูป iPhone ที่ต้องการ
                    alt="iPhone Models"
                    sx={{
                      width: { xs: "90%", sm: "70%", md: "80%" },
                      maxWidth: 550,
                      height: "auto",
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  mt: { xs: 3, md: 0 },
                }}
              >
                <Container maxWidth="xs">
                  <Box>
                    <Card
                      style={{ minWidth: "max-content" }}
                      sx={{
                        position: "relative",
                        borderRadius: 4,
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        overflow: "visible", // เพิ่มบรรทัดนี้
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: colors.themeMainColor,
                          p: 1,
                          fontWeight: 700,
                          fontSize: { xs: "18px", lg: "22px" },
                          borderRadius: 30,
                          textAlign: "center",
                          position: "absolute",
                          top: -22,
                          left: { xs: "14%", md: "8%", lg: "11%" },
                          color: colors.white,
                        }}
                      >
                        เพียงนำไอโฟนเครื่องเก่ามาเทิร์น
                      </Box>

                      <CardContent
                        sx={{
                          pt: 5,
                          pb: 4,
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {/* --- คอลัมน์ซ้าย: ข้อความ "รับส่วนลดเงินดาวน์สูงสุด" --- */}
                          <Grid
                            item
                            xs="auto"
                            md="auto" // ขยายตามเนื้อหา, หรือจะใช้ md={6} ก็ได้
                            textAlign={{ xs: "center", md: "left" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 30,
                                fontWeight: 700,
                                color: colors.themeMainColor,
                                lineHeight: 1,
                              }}
                            >
                              รับส่วนลด
                              <br />
                              <span
                                style={{ fontSize: 20, color: colors.black }}
                              >
                                เงินดาวน์สูงสุด
                              </span>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs="auto"
                            md="auto"
                            textAlign={{ xs: "center", md: "right" }}
                          >
                            <Typography
                              component="div"
                              sx={{
                                fontSize: { xs: "44px", lg: "75px" },
                                fontWeight: 700,
                                color: colors.themeMainColor,
                                lineHeight: 1,
                              }}
                            >
                              1,500
                              <Box
                                component="span"
                                sx={{ fontSize: "4rem", fontWeight: 500 }}
                              >
                                .-
                              </Box>
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box
                    sx={{
                      pt: 5,
                    }}
                  >
                    <Card
                      sx={{
                        position: "relative",
                        borderRadius: 4,
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        overflow: "visible", // เพิ่มบรรทัดนี้
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: colors.yellow,
                          p: 1,
                          fontWeight: 700,
                          fontSize: { xs: "18px", lg: "22px" },
                          borderRadius: 30,
                          textAlign: "center",
                          position: "absolute",
                          top: -22,
                          left: { xs: "14%", md: "8%", lg: "11%" },
                        }}
                      >
                        อยากมีใช้แต่ไม่ต้องการมือหนึ่ง
                      </Box>

                      <CardContent
                        sx={{
                          textAlign: "center",
                          pt: 5,
                          pb: 4,
                          position: "relative",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            mb: 1,
                          }}
                        >
                          ผ่อนเครื่องมือสอง
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {/* --- คอลัมน์ซ้าย: ข้อความ "รับส่วนลดเงินดาวน์สูงสุด" --- */}
                          <Grid
                            item
                            xs="auto"
                            md="auto" // ขยายตามเนื้อหา, หรือจะใช้ md={6} ก็ได้
                            textAlign={{ xs: "center", md: "right" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 30,
                                fontWeight: 700,
                                color: colors.themeMainColor,
                                lineHeight: 1,
                              }}
                            >
                              เริ่มต้น
                              <br />
                              เพียง
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs="auto"
                            md="auto"
                            textAlign={{ xs: "center", md: "right" }}
                          >
                            <Typography
                              component="div"
                              sx={{
                                fontSize: { lg: "75px", xs: "48px" },
                                fontWeight: 700,
                                color: colors.themeMainColor,
                                lineHeight: 1,
                              }}
                            >
                              900
                              <Box
                                component="span"
                                sx={{ fontSize: "4rem", fontWeight: 500 }}
                              >
                                .-
                              </Box>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography
                          sx={{
                            mt: 1,
                            color: "#444",
                            fontSize: "15px",
                          }}
                        >
                          ประกันร้าน 30 วัน งวดชำระถูกกว่า
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>

                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Typography
                      variant="caption"
                      sx={{ color: "#999", fontSize: "12px", lineHeight: 1.4 }}
                    >
                      *หมายเหตุ เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด
                    </Typography>
                  </Box>
                </Container>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Style.Container>
          <Grid container>
            {/* Left Text */}
            <Grid xs={12} md={7}>
              <Typography
                style={{ fontWeight: 400 }}
                sx={{
                  typography: { xl: "h5", lg: "h5", md: "h5", xs: "h6" },
                  textAlign: { md: "left", xs: "center" },
                }}
              >
                และยังมีโปรโมชั่นสุดคุ้มทุกเดือน!
                <Box
                  component="br"
                  sx={{ display: { xs: "block", md: "none" } }}
                />
                ทักเข้ามาสอบถาม
                <Box
                  component="br"
                  sx={{ display: { xs: "none", xl: "block" } }}
                />
                กับ Ufriend ได้เลย
                <Box
                  component="br"
                  sx={{ display: { xs: "block", md: "none" } }}
                />
                แอดมินยินดี{" "}
                <Box
                  component="span"
                  sx={{ color: colors.themeMainColor, fontWeight: 500 }}
                >
                  ให้บริการตลอด 24 ชั่วโมง
                </Box>
              </Typography>
            </Grid>

            {/* Right Button */}
            <Grid
              xs={12}
              md={5}
              sx={{ textAlign: "center", pt: { xs: 2, md: 0 } }}
            >
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.themeMainColor,
                    color: colors.yellow,
                    fontWeight: 500,
                    padding: "10px 20px",
                    fontSize: "20px",
                    borderRadius: "30px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => navigateToRefinance()}
                >
                  <Typography
                    style={{ fontWeight: 700 }}
                    sx={{
                      typography: { xl: "h4", md: "h6", xs: "h6" },
                    }}
                  >
                    ผ่อนง่าย สมัครเลย
                  </Typography>
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Style.Container>

        <Grid container sx={{ textAlign: "center", pt: { xs: 2, md: 4 } }}>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={
                isMdUp ? imagesInstallment.UFW1_13 : imagesInstallment.MUF1_12
              }
              alt=""
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ position: "relative" }}>
              <Box
                component="img"
                src={
                  isMdUp ? imagesInstallment.UFW1_14 : imagesInstallment.MUF1_13
                }
                alt=""
                sx={{ width: "100%" }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: { lg: "18%", md: "12%", xs: "14%" },
                  left: { lg: "50%", md: "51%", xs: "49%" },
                  transform: "translateX(-50%)",
                }}
              >
                <motion.div
                  animate={{ scale: [1, 1.1, 1] }} // Pulsing effect
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.white,
                      color: colors.themeMainColor,
                      fontWeight: "bold",
                      px: 3,
                      py: 1,
                      borderRadius: "25px",
                      "&:hover": {
                        backgroundColor: "#FFD700",
                      },
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => navigateToRefinance()}
                  >
                    <Typography
                      style={{ fontWeight: 700 }}
                      sx={{
                        typography: { lg: "h4", sm: "subtitle2", xs: "h6" },
                      }}
                    >
                      สมัครผ่อนเลย คลิก
                    </Typography>
                  </Button>
                </motion.div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          position: "fixed",
          width: "48px",
          height: "48px",
          zIndex: 1,
          cursor: "pointer",
          bottom: "40px",
          right: "15px",
        }}
      >
        <Box
          component="img"
          src={imgs.lineApp}
          alt=""
          sx={{ width: "100%" }}
          onClick={() => {
            navigateToLine();
          }}
        />
      </div>
      <div ref={sectionRef4}>
        <Footer2 type="INSTALLMENT" />
      </div>
    </>
  );
}
