import React from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import iconFb from "../../assets/icon/iconFB.svg"
import iconLine from "../../assets/icon/iconLine.svg"
import iconTell from "../../assets/icon/iconTell.svg"
import iconTiktok from "../../assets/icon/iconTiktok.svg"
import iconYt from "../../assets/icon/iconYT.svg"
import { imgs } from "../../constants/images";
import { colors } from "../../constants/colors";

type footer = {
    type?: string
}
export default function Footer2(props: footer) {

    const navigateTo = (link: any) => {
        window.open(link, "_blank");
    }

    const social = [
        {
            icon: iconFb,
            link: "https://www.facebook.com/ufriendth",
            name: 'ufriendth'
        },
        {
            icon: iconLine,
            link: "https://page.line.me/945uzjoj?openQrModal=true",
            name: '@ufriendth'
        },
        {
            icon: iconTiktok,
            link: "https://www.tiktok.com/@ufriendshop",
            name: '@ufriendshop'
        },
        {
            icon: iconYt,
            link: "https://www.youtube.com/@ufriendshop",
            name: '@ufriendshop'
        },
        {
            icon: iconTell,
            link: "tel:0646169699",
            name: '064-616-9699'
        },
    ]
    return (
        <Box sx={{ backgroundColor: colors.header, color: "white", py: 4, px: { xs: 2, md: 6 } }}>
            <Box sx={{ backgroundColor: colors.header, color: "white" }}>
                <Grid container
                    sx={{
                        justifyContent: "center",
                    }}>

                    {/* Left Section - Logo & Tagline */}
                    <Grid item xs={12} md={3} >
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" }, alignItems: "center", mb: 1 }}>
                            <Box
                                component="img"
                                src={props.type == 'INSTALLMENT' ? imgs.footerInstallment : imgs.footerRefinance}
                                alt="Ufriend Logo"
                                sx={{ mr: 1, width: { lg: '80%', md: '80%', xs: '82%' } }}
                            />
                        </Box>
                    </Grid>

                    {/* Center Section - Menu */}
                    {/* <Grid item xs={12} md={2} lg={1} sx={{ mt: { xl: 6, lg: 5 }, textAlign: { xs: "center", md: "left" }, display: { md: 'block', xs: 'none' } }}>
                        <Typography sx={{ typography: { lg: 'h5', md: 'h6', xs: 'h6' } }} fontWeight="bold">Menu</Typography>
                        {["Home", "Service", "Review", "Contacts"].map((item, index) => (
                            <Typography key={index} variant="body2" sx={{ mt: 1, typography: { lg: 'body1', md: 'body1', xs: 'body2' } }}>
                                {item}
                            </Typography>
                        ))}
                    </Grid> */}
                    {/* Right Section - Contact & Social Media */}
                    <Grid item xs={12} md={3} lg={2} sx={{ mt: { xl: 6, lg: 5 }, py: { lg: 0, xs: 0 }, justifyContent: { xs: "center", md: "left" } }}>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" } }}>
                            <Typography sx={{ pt: { xs: 1, lg: 0 }, typography: { lg: 'h6', md: 'h6', xs: 'h4' } }} fontWeight="bold">ติดต่อเรา</Typography>
                        </Box>
                        {social.map((item, index) => (
                            <Box sx={{ display: "flex", alignItems: 'center', pl: { xs: 16, sm: 0 }, justifyContent: { xs: "left", md: "left" }, mt: 1 }}>
                                <Box
                                    component="img"
                                    src={item.icon}
                                    alt={`icon-${index}`}
                                    sx={{ display: "flex", width: '10%', justifyContent: { xs: "center", md: "left" } }}
                                />
                                <Box>
                                    <Typography variant="body2" sx={{ ml: 1, typography: { lg: 'body1', md: 'body2', xs: 'body1' } }}>{item.name}</Typography>
                                </Box>
                            </Box>
                        ))}
                        {/* <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" }, mt: 1 }}>
                            <FacebookIcon />
                            <Typography variant="body2" sx={{ ml: 1, typography: { lg: 'body1', md: 'body2', xs: 'body2' } }}>ufriendth</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" }, mt: 1 }}>
                            <InstagramIcon />
                            <Typography variant="body2" sx={{ ml: 1, typography: { lg: 'body1', md: 'body2', xs: 'body2' } }}>@ufriend</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" }, mt: 1 }}>
                            <YouTubeIcon />
                            <Typography variant="body2" sx={{ ml: 1, typography: { lg: 'body1', md: 'body2', xs: 'body2' } }}>@ufriendshop</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" }, mt: 1 }}>
                            <PhoneIcon />
                            <Typography variant="body2" sx={{ ml: 1, typography: { lg: 'body1', md: 'body2', xs: 'body2' } }}>064-616-9699</Typography>
                        </Box> */}
                    </Grid>

                    {/* Download Section */}
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" }, alignItems: "center", mb: 1 }}>
                            <Box
                                component="img"
                                src={imgs.footer}
                                alt="Ufriend App Icon"
                                sx={{ width: { lg: '74%', md: '80%', xs: '82%' }, mr: { lg: 3 } }}
                            />
                        </Box>
                        <Box sx={{
                            display: { lg: 'flex', sm: 'column', xs: "flex" },
                            justifyContent: { xs: "center", md: "flex-start" }, mt: { lg: 2 }, ml: { md: 2, lg: 5, xl: 7 }
                        }}>
                            <IconButton>
                                <Box
                                    component="img"
                                    src={imgs.appleStore}
                                    alt="App Store"
                                    sx={{ width: { lg: 155, md: 110, xs: 120 } }}
                                />
                            </IconButton>
                            <IconButton sx={{ ml: { lg: 1, xl: 2 } }}>
                                <Box
                                    component="img"
                                    src={imgs.playStore}
                                    alt="Google Play"
                                    sx={{ width: { lg: 155, md: 110, xs: 120 } }}
                                />
                            </IconButton>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Box >
    );
}
