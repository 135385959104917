import { styled } from "@mui/material/styles";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Header from "./components/header/header";
import routes from "./routes/router";

import * as amplitude from "@amplitude/analytics-browser";
import React, { useEffect } from "react";
import Footer from "./components/footer/footer";

const ContainerComponent = styled("div")(() => ({
  paddingLeft: "10px",
  paddingRight: "10px",
  "&.no-appBar": {
    top: "0 !important",
    // height: '100vh !important'
  },
}));

const ContainerContent = styled("div")(() => ({
  width: "100%",
  "> div": {
    width: "100% !important",
    display: "block !important",
    minHeight: "unset !important",
    border: "none !important",
    boxShadow: "none important",
    marginTop: "0 !important",
  },
}));

export function App() {
  function PrivateRoute({ exact, path, key, component: Component }: any) {
    let navigate = path;
    return (
      <Route exact={exact} path={navigate} key={key}>
        {Component}
      </Route>
    );
  }
  const key = process.env.REACT_APP_AMPLITUDE_KEY || "";

  useEffect(() => {
    amplitude.init(key, {
      defaultTracking: {
        attribution: false,
        pageViews: false, //[Amplitude] Page Viewed
        // sessions: false, //[Amplitude] Start Session
        formInteractions: false, //[Amplitude] Form Submitted
        fileDownloads: false,
      },
      cookieOptions: {
        domain: `.${process.env.REACT_COOKIE_DOMAIN}`,  // Enables tracking across subdomains
        expiration: 365,            // Days before the cookie expires (default: 365)
        secure: false,               // Ensures cookies are only set over HTTPS
        sameSite: 'Lax',            // Controls cross-site behavior ('Strict' | 'Lax' | 'None')
      },
    })
  }, []);

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path={"/"}>
            <Redirect to="/register" />
          </Route>
          {routes.map(({ name, path, component, key, header, footer, container }, idx) => (
            <PrivateRoute
              exact
              path={path}
              key={name || idx}
              keyName={key}
              component={
                <>
                  {header && <Header />}
                  {container ? (
                    <ContainerComponent className={`div-component d-flex flex-column no-appBar`}>
                      <ContainerContent>{component && React.createElement(component)}</ContainerContent>
                    </ContainerComponent>
                  ) : (
                    component && React.createElement(component)
                  )}
                  {footer && <Footer />}
                </>
              }
            />
          ))}
          <Route path="*">
            <Redirect to="/register" />
          </Route>
        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
}
