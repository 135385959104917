import axios from "axios";

export const apiUrl = process.env.REACT_APP_API_CAMPAIGN_SERVICE;
const instance = axios.create({
  baseURL: apiUrl || process.env.REACT_APP_API_CAMPAIGN_SERVICE,
  timeout: 100000,
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log(error);
  }
);

export default class BaseAPICampaign {
  static api = instance;
}
