import { styled } from "@mui/material/styles";
import { colors } from "../../constants/colors";

export const ContainerCard = styled(`div`)(() => ({
  width: "100%",
  overflowX: "auto",
  display: "flex",
  flexDirection: "row",
}));

export const Container = styled(`div`)(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: "100px 300px",

  [theme.breakpoints.down('xl')]: {
    padding: "100px 250px",
  },
  [theme.breakpoints.down('lg')]: {
    padding: "100px 100px",
  },
  [theme.breakpoints.down('md')]: {
    padding: "100px 20px",
  }
}));

export const HeaderText = styled(`div`)(() => ({
    fontSize: 24,
    fontWeight: 700,
    color: colors.black
  }));
