import moment from "moment";

export const dateTimeToView = (
  date?: any,
  isFullYear = true,
  locales = "th"
) => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return (
    new Intl.DateTimeFormat(locales, {
      year: isFullYear ? "numeric" : "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }).format(today) + " น."
  );
};

export const dateToView = (date?: any, isFullYear = true, locales = "th") => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return new Intl.DateTimeFormat(locales, {
    year: isFullYear ? "numeric" : "2-digit",
    month: "2-digit",
    day: "2-digit",
  }).format(today);
};

export const timeToView = (date?: any, isFullYear = true, locales = "th") => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return new Intl.DateTimeFormat(locales, {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(today);
};

enum SHORT_DAY_OF_WEEK {
  Sun = `Sun`,
  Mon = `Mon`,
  Tue = `Tue`,
  Wed = `Wed`,
  Thu = `Thu`,
  Fri = `Fri`,
  Sat = `Sat`,
}
export const dateOfWeek = (type: any = "SHORT") => {
  const dateOfWeek = SHORT_DAY_OF_WEEK;
  return type === "SHORT" ? SHORT_DAY_OF_WEEK : [];
};

export const fullDateToView = (
  date?: any,
  isFullYear = true,
  locales = "th"
) => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return new Intl.DateTimeFormat(locales, {
    year: "numeric",
    month: "long",
  }).format(today);
};

export const fullDate = (
  date?: any,
  isFullYear = true,
  locales = "th"
) => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return new Intl.DateTimeFormat(locales, {
    day: "numeric",
    year: "numeric",
    month: "long",
  }).format(today);
};

export const shortnessDate = (
  date?: any,
  isFullYear = true,
  locales = "th"
) => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return new Intl.DateTimeFormat(locales, {
    day: "numeric",
    month: "short",
  }).format(today);
};
export const shortnessDatemonth = (
  date?: any,
  isFullYear = true,
  locales = "th"
) => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return new Intl.DateTimeFormat(locales, {
    month: "long",
  }).format(today);
};
export const lnessDatemonth = (
  date?: any,
  isFullYear = true,
  locales = "th"
) => {
  let today: any = Date.now();
  if (date) today = new Date(date);
  return new Intl.DateTimeFormat(locales, {
    month: "short",
  }).format(today);
};

export const getMonthMinusMonths = (date: any, months: any) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() - months);
  if (result.getDate() !== date.getDate()) {
    result.setDate(0); // ตั้งค่าเป็นวันสุดท้ายของเดือนก่อนหน้า
  }
  return result;
}

export const monthOnly = (
  monthNumber: any
) => {
  const months = [
    'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
    'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
  ];
  if (monthNumber <= 12) {
    return months[monthNumber];
  } else {
    return 'Invalid Month';
  }
};


export const getAgeMonth = (dateString: string) => {
  const date = moment(dateString, "DD/MM/YYYY").subtract(543, 'years').format('MM/DD/YYYY')
  // ใช้ปี ค.ศ. คิด

  const birthDate = new Date(date);
  const today = new Date();

  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();
  if (months < 0) {
    years--;
    months += 12;
  }
  if (today.getDate() < birthDate.getDate()) {
    months--;
    if (months < 0) {
      years--;
      months += 12;
    }
  }
  return { years, months };
}

export const getAgeMonthEn = (dateString: string) => {
  const date = moment(dateString).format('MM/DD/YYYY')
  // ใช้ปี ค.ศ. คิด
  const birthDate = new Date(date);
  const today = new Date();

  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }
  if (today.getDate() < birthDate.getDate()) {
    months--;
    if (months < 0) {
      years--;
      months += 12;
    }
  }

  return { years, months };
}
