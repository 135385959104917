import { useState } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { colors } from '../../constants/colors';
import ModalCustom from '../modalCustom/modalCustom';
import useWindowSize from '../../utils/useWindowSize';
import { LinearProgress } from '@mui/material';

type UploadImageListProps = {
    onDelete?: () => void
    preview?: any
}

export default function UploadImageList(props: UploadImageListProps) {
    const [showImg, setShowImg] = useState<boolean>(false)
    const [width, height] = useWindowSize()

    return (
        <>
            {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", border: "1px solid lightgray", padding: 10, maxWidth: 460 }} >
                <div style={{ display: "flex", alignItems: "center", gap: 15, cursor: 'pointer' }}>
                    <img onClick={() => { setShowImg(true) }} src={`${props.preview.img}`} width="50" height="50" />
                    <div className='text-ellipsis' style={{ width: width <= 575 ? 230 : 350 }}>{props.preview.name}</div>
                </div>
                <div>
                    <DeleteOutlineIcon
                        onClick={() => { props.onDelete && props.onDelete() }}
                        sx={{ cursor: "pointer", marginRight: "10px", color: colors.red }}
                    />
                </div>
            </div > */}
            <div style={{ alignItems: "center", border: "1px solid lightgray", padding: 10, maxWidth: 460 }} >
                <div className='d-flex'>
                    <img src={`${props.preview?.img}`} width="50" height="50" />
                    <div style={{ paddingLeft: 15, paddingTop: 13 }}>
                        <div className='text-ellipsis' style={{ width: width <= 575 ? 230 : 350, paddingBottom: 8 }}>{props.preview.name}</div>
                        {!props.preview?.fileName && (
                            <LinearProgress sx={{ color: colors.themeMainColor }} />
                        )}
                    </div>
                    <div style={{paddingTop:10}}>
                        <DeleteOutlineIcon
                            onClick={() => { props.onDelete && props.onDelete() }}
                            sx={{ cursor: "pointer", marginRight: "10px", color: colors.red }}
                        />
                    </div>
                </div>
            </div >

            <ModalCustom
                size="lg"
                title={''}
                modalTheme="default"
                onSubmit={() => { setShowImg(false) }}
                onClose={() => { setShowImg(false) }}
                textBtnConfirm={'ยืนยัน'}
                isShowProps={showImg}
                closeButton
                component={
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '20px',
                            }}
                        >
                            <img width={400} src={`${props.preview.img}`} />
                        </div>
                    </>
                }
            />
        </>
    )
}
