
import React from 'react'
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals'
import { CssBaseline } from '@mui/material'
import { create } from 'jss'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { StylesProvider, jssPreset } from '@mui/styles'

import 'moment/locale/en-gb'
import 'moment/locale/th'

/** STYLES */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './index.css'
import './styles/input.css'
import './styles/custom.css'
import './App.css'
import { App } from './App';


const jss = create({
  ...jssPreset()
})

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,  // ปรับเป็น 768px
      lg: 1200,
      xl: 1536,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 2050,
    snackbar: 1400,
    tooltip: 1500
  }
})

// const theme = unstable_createMuiStrictModeTheme()
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <CssBaseline />
        {/* <Provider store={store}> */}
        {/* <head> */}
        <noscript id="jss-insertion-point" />
        {/* </head> */}
        <App />
        {/* </Provider> */}
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
