import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModalCustom from "../../../../components/modalCustom/modalCustom";
import { colors } from "../../../../constants/colors";
import { StyledBoxCenter } from "./ModalFilmFocusFinish.style";
import { Grid, Link, useMediaQuery } from "@mui/material";
import * as Cons from "../constant/FilmFocus.constant";
import { Typography } from "@material-ui/core";
import { imgs } from "../../../../constants/images";
import { useTheme } from "@mui/material/styles";

interface ModalFilmFocusFinishProps {
  isShowModal: boolean;
  setIsShowMoal: React.Dispatch<React.SetStateAction<boolean>>;
  content: string;
}

const ModalFilmFocusFinish: React.FC<ModalFilmFocusFinishProps> = ({ isShowModal, setIsShowMoal, content }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ModalCustom
      size="lg"
      title={""}
      modalTheme="default"
      onSubmit={() => setIsShowMoal(false)}
      onClose={() => {
        setIsShowMoal(false);
      }}
      textBtnConfirm={"ตกลง"}
      isShowProps={isShowModal}
      component={
        <>
          <Grid container spacing={2} sx={{ padding: "1rem" }}>
            <CheckCircleIcon
              style={{
                margin: "auto",
                fontSize: isMobile ? "54px" : "85px",
                color: colors.green,
              }}
            />

            <Grid item {...Cons.GRID_ITEM_FULL} sx={{ paddingTop: "0 !important" }}>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                ขอบคุณที่ร่วมสนุกกับยูเฟรนด์
              </Typography>
            </Grid>

            <Grid item {...Cons.GRID_ITEM_FULL} sx={{ paddingTop: 0 }}>
              <StyledBoxCenter dangerouslySetInnerHTML={{ __html: content }}></StyledBoxCenter>
            </Grid>

            <Grid item {...Cons.GRID_ITEM_FULL}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Link href="https://lin.ee/FJBOd2R" target="_blank">
                    <img src={imgs.campaignUfiendClick} width={isMobile ? 130 : 200} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://lin.ee/JlYn6I5" target="_blank">
                    <img src={imgs.campaignFlimClick} width={isMobile ? 130 : 200} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ModalFilmFocusFinish;
