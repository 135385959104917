

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Link } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { colors } from '../../constants/colors';
import { imgs } from '../../constants/images';
// import { amplitudeTrack } from '../../utils/amplitude/amplitude';
import iconFb from "../../assets/icon/iconFB.svg"
import iconLine from "../../assets/icon/iconLine.svg"
import iconTell from "../../assets/icon/iconTell.svg"
import iconTiktok from "../../assets/icon/iconTiktok.svg"
import iconYt from "../../assets/icon/iconYT.svg"


const logoStyle = {
  width: "10%",
  // height: '65px',
  cursor: 'pointer',
  // borderRadius: '50%',
};

const navigateTo = (link: any) => {
  window.open(link, "_blank");
}

interface header {
  scrollToSection?: () => void
  scrollToSection2?: () => void
  scrollToSection3?: () => void
  scrollToSection4?: () => void
}

export default function Header2(props: header) {
  const [open, setOpen] = useState<boolean>(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    setOpen(false)
  }, [props.scrollToSection, props.scrollToSection2, props.scrollToSection3, props.scrollToSection4]);

  const menu = [
    {
      id: 1, label: 'Home',
      link: props.scrollToSection
      // key: 'H_Header_O_lease'
    },
    {
      id: 2, label: 'Service',
      link: props.scrollToSection2
      // key: 'H_Header_O_deposit'
    },
    {
      id: 3, label: 'Review',
      link: props.scrollToSection3,
      // key: 'H_Header_O_refin'
    },
    {
      id: 4, label: 'Contacts',
      link: props.scrollToSection4,
      // key: 'H_Header_O_ourproduct'
    },
    // {
    //   id: 5, label: 'เกี่ยวกับยูเฟรนด์',
    //   link: 'https://www.ufriend.shop/%e0%b9%80%e0%b8%81%e0%b8%b5%e0%b9%88%e0%b8%a2%e0%b8%a7%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b8%a2%e0%b8%b9%e0%b9%80%e0%b8%9f%e0%b8%a3%e0%b8%99%e0%b8%94%e0%b9%8c/',
    //   // key: 'H_Header_O_ufriend'
    // },
    // {
    //   id: 6, label: 'บทความ',
    //   link: 'https://www.ufriend.shop/%e0%b8%9a%e0%b8%97%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1/',
    //   // key: 'H_Header_O_topic'
    // },
    // {
    //   id: 7, label: 'สาขาใกล้คุณ',
    //   link: '/near-branch',
    //   // key: 'H_Header_O_branch'
    // },
    // {
    //   id: 8, label: 'ร่วมงานกับเรา',
    //   link: 'https://www.ufriend.shop/%e0%b8%a3%e0%b9%88%e0%b8%a7%e0%b8%a1%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b9%80%e0%b8%a3%e0%b8%b2-new/',
    //   // key: 'H_Header_O_career'
    // },
  ]
  const social = [
    {
      icon: iconFb,
      link: "https://www.facebook.com/ufriendth",
    },
    {
      icon: iconLine,
      link: "https://page.line.me/945uzjoj?openQrModal=true",
    },
    {
      icon: iconTell,
      link: "tel:0646169699",
    },
    {
      icon: iconTiktok,
      link: "https://www.tiktok.com/@ufriendshop",
    },
    {
      icon: iconYt,
      link: "https://www.youtube.com/@ufriendshop",
    },
  ]

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          maxHeight: '100%',
          maxWidth: '100%',
          minHeight: 70
        }}
      >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: "relative",
            flexShrink: 0,
            backdropFilter: 'blur(24px)',
            bgcolor: `${colors.header}`,
            maxHeight: 80,
            border: '1px solid',
            borderColor: 'divider',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              px: 0,
              justifyContent: "space-between"
            }}
          >
            <Box display="flex" gap={2}>

              <Box
                component="img"
                src={imgs.logoHorizontal}
                alt="Review Section"
                sx={{
                  width: { lg: "10%", xs: '30%' },
                }}
              />
              {/* <Box sx={{ display: { lg: 'flex', xs: 'none' }, justifyContent: 'center' }}>
                {
                  menu.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        sx={{ py: '6px', px: '12px' }}
                        key={index}
                        // onClick={() => { amplitudeTrack(item.key) }}
                        onClick={item.link}
                      >
                        <Typography variant="body2" color={colors.white} >
                          {item.label}
                        </Typography>
                      </MenuItem>
                    )
                  })
                }
              </Box> */}
            </Box>
            {/* {social.map((item, index) => (
              <Box
                component="img"
                src={item.icon}
                alt={`icon-${index}`}
                sx={{ width: '2%', mb: 1, alignItems: 'end', cursor: 'pointer', display: { lg: 'flex', xs: 'none' } }}
                onClick={() => navigateTo(item.link)}
              />
            ))} */}
          </Box>

          {/* <Box sx={{ display: { sm: '', lg: 'none' } }}>
            <Button
              variant="text"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ color: colors.white, minWidth: '30px', p: '4px', position: 'absolute', right: 10, top: "50%", transform: "translateY(-50%)" }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    flexGrow: 1,
                  }}
                >
                </Box>
                {
                  menu.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        sx={{ py: '6px', px: '12px' }}
                        key={index}
                        // onClick={() => { amplitudeTrack(item.key) }}
                        onClick={item.link}
                      >
                        <Typography variant="body2" color={colors.black} >
                          {item.label}
                        </Typography>
                      </MenuItem>
                    )
                  })
                }
                <Divider />
              </Box>
            </Drawer>
          </Box> */}
        </Toolbar>
      </AppBar>
    </div >
  );
}